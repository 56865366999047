export default {
    state: {
        stockCars: '',
        stockDetail: ''
    },
    mutations: {
        setStockCars(state, payload) {
            state.stockCars = payload
        },
        setStockDetail(state, payload) {
            state.stockDetail = payload
        }
    },
    actions: {
        getStockCars({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car_main/admin/'})
            .then(response => response.json())
            .then(data => {
                commit('setStockCars', data)
            })
        },
        getStockDetail({dispatch, commit}, carId) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car_main/admin/detail/' + carId + '/'})
            .then(response => response.json())
            .then(data => {
                commit('setStockDetail', data)
            })
        }
    },
    getters: {
        getStockCars(state) {
            return state.stockCars
        },
        getStockDetail(state) {
            return state.stockDetail
        }
    }
}