<template>
  <section class="search overflow-hidden">
    <div class="container">
      <h1 class="title" v-if="section === 'auction'">Продажа легковых автомобилей: <span>{{ count }} предложений в каталоге</span>
      </h1>
      <h1 class="title" v-else>Поиск авто на аукционах</h1>
      <div class="row search-auction-icons">
        <div class="col-md col-6 search-auction-item" v-for="item in auctions" :key="item">
          <div class="search-radio-img" v-on:click="selectAuction(item)">
            <div class="search-radio" :class="(auction?.includes(item.id)) && 'active'"></div>
            <img :src="item.image" :alt="item.title" v-if="item.image">
            <i class="search-auction-flag" v-if="item.flag" :class="item.flag"></i>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md col-6">
          <label class="search-label">Тип авто</label>
          <select class="search-select" v-model="vehicle">
            <option v-for="vehicle in vehicles" :key="vehicle" :value="vehicle.title_en">
              {{ vehicle.title }}
            </option>
          </select>
        </div>
        <div class="col-md col-6">
          <label class="search-label">Марка</label>
          <select class="search-select" v-model="brand">
            <option value="">Все марки</option>
            <option v-for="brand in brands" :key="brand" :value="brand.title">{{ brand.title }}</option>
          </select>
        </div>
        <div class="col-md col-6">
          <label class="search-label">Год от</label>
          <select class="search-select" v-model="yearFrom">
            <option value="">---</option>
            <option v-for="i in 24" :key="i" :value="year(i)" :selected="(year(i) == yearFrom) && 'selected'">{{
                year(i)
              }}
            </option>
          </select>
        </div>
        <div class="col-md col-6">
          <label class="search-label">Год до</label>
          <select class="search-select" v-model="yearTo">
            <option value="">---</option>
            <option v-for="i in 24" :key="i" :value="year(i)" :selected="(year(i) == yearTo) && 'selected'">{{
                year(i)
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-3">
        <div class="d-flex justify-content-between my-4">
          <label class="search-label">Цена от</label>
          <label class="search-label">Цена до</label>
        </div>
        <div class="pt-3">
          <Slider :min="min" :max="max" :merge="merge" :format="format" v-model="value"></Slider>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <span class="search-button blink-animate mt-4" v-on:click="search">Cмотреть варианты</span>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from '@vueform/slider'

export default {
  props: {
    section: String,
    count: String,
    dataFilter: Object,
  },
  data() {
    return {
      min: 0,
      max: 100000,
      value: [this.$route.query['priceFrom'] ? this.$route.query['priceFrom'] : 0, this.$route.query['priceTo'] ? this.$route.query['priceTo'] : 100000],
      merge: 0,
      format: {
        prefix: '$',
        decimals: 0
      },
      vehicle: this.$route.query['vehicle_type'] ? this.$route.query['vehicle_type'] : 'AUTOMOBILE',
      auction: this.$route.query['auction'] ? decodeURI(this.$route.query['auction']).split(',') : [],
      brand: this.dataFilter?.brand || '',
      yearMin: 1999,
      yearMax: new Date().getFullYear() + 2,
      yearFrom: this.dataFilter?.yearFrom || '',
      yearTo: this.dataFilter?.yearTo || '',
      priceFrom: '',
      priceTo: ''
    }
  },
  components: {
    Slider
  },
  created() {
    if (this.$route.query['auction']) {
      this.auction = this.auction.map(function (x) {
        return parseInt(x, 10)
      })
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    vehicles() {
      return [
        {
          "id": 1,
          "title": "Автомобили",
          title_en: 'AUTOMOBILE'
        },
        {
          "id": 35,
          "title": "Мотоциклы",
          title_en: 'MOTORCYCLE'
        },
        {
          "id": 36,
          "title": "Квадрациклы",
          title_en: 'ATV'
        },
        {
          "id": 37,
          "title": "Водные мотоциклы/катера",
          title_en: 'JET SKI'
        },
        {
          "id": 38,
          "title": "Трейлеры",
          title_en: 'TRAILER'
        },
        {
          "id": 42,
          "title": "Тяжелая спецтехника",
          title_en: 'HEAVY DUTY TRUCKS'
        },
        {
          "id": 45,
          "title": "Спец. машины",
          title_en: 'SPECIALIST'
        },
        {
          "id": 46,
          "title": "Краны",
          title_en: 'CRANE'
        },
        {
          "id": 39,
          "title": "Грузовики",
          title_en: 'TRUCK'
        },
        {
          "id": 49,
          "title": "Другие",
          title_en: 'OTHER'
        },
        {
          "id": 40,
          "title": "Микроавтобусы/Cargo",
          title_en: 'MINIBUSES/CARGO'
        },
        {
          "id": 41,
          "title": "Прицепы и п/прицепы",
          title_en: 'TRAILERS'
        },
        {
          "id": 43,
          "title": "Сельхоз. техника",
          title_en: 'AGRICULTURAL'
        },
        {
          "id": 44,
          "title": "Промышленная техника",
          title_en: 'INDUSTRIAL EQUIPMENT'
        }
      ]
    },
    auctions() {
      return (this.$store.getters.getAuctionList)
          ? this.$store.getters.getAuctionList
          : this.$store.dispatch('getAuctionSecondList')
    },
    brands() {
      return (this.$store.getters.getBrandList)
          ? this.$store.getters.getBrandList
          : this.$store.dispatch('getBrandList', this.vehicle)
    },
  },
  watch: {
    $route() {
      this.vehicle = this.$route.query['vehicle_type'] || 'AUTOMOBILE'
      this.brand = this.$route.query['brand']
      this.model = this.$route.query['model']
      this.yearFrom = this.$route.query['min_year']
      this.yearTo = this.$route.query['max_year']
      this.auction = this.$route.query['auction']?.split(',').map(function (x) {
        return parseInt(x, 10)
      })
    },
    value() {
      this.priceFrom = this.value[0]
      this.priceTo = this.value[1]
    },
    vehicle() {
      if (this.vehicle !== '') {
        this.brand = ''
        this.model = ''
        this.$store.dispatch('getBrandList', this.vehicle)
      }
    },
    brand() {
      if (this.brand) {
        this.$store.dispatch('getModelList', this.brand)
      } else {
        this.$store.commit('setModelList', '')
      }
    }
  },
  methods: {
    year(count) {
      return this.yearMax - count
    },
    selectAuction(item) {
      if (this.auction?.includes(item.id)) {
        this.auction = this.auction.filter(e => e !== item.id)
      } else {
        this?.auction?.push(item.id)
      }
    },
    search() {
      var query = {
        'vehicle_type': this.vehicle ? this.vehicle : 'AUTOMOBILE',
        brand: this.brand ? this.brand : '',
        'min_year': this.yearFrom ? this.yearFrom : '',
        'max_year': this.yearTo ? this.yearTo : '',
        priceFrom: this.priceFrom ? this.priceFrom : '',
        priceTo: this.priceTo ? this.priceTo : '',
        sort_date: this.$route.query['sort_date'] || '',
        sort_year: this.$route.query['sort_year'] || '',
        brand_sort: this.$route.query['brand_sort'] || '',
        model_sort: this.$route.query['model_sort'] || '',
        model: this.$route.query['model'] || '',
        mileage_from: this.$route.query['mileage_from'] || 0,
        mileage_to: this.$route.query['mileage_to'] || 1000000000,
        body: this.$route.query['body'] || '',
        location: this.$route.query['location'] || '',
        fuel_type: this.$route.query['fuel_type'] || '',
        transmission: this.$route.query['transmission'] || '',
        color_f: this.$route.query['color_f'] || '',
        buy_now: this.$route.query['buy_now'] || '',
        airbags: this.$route.query['airbags'] || '',
        drive_unit: this.$route.query['drive_unit'] || '',
        today_auction: this.$route.query['today_auction'] || false,
        tomorrow_auction: this.$route.query['tomorrow_auction'] || false,
        this_week_auction: this.$route.query['this_week_auction'] || false,
      }

      this.auction = this.auction?.filter(item => !isNaN(item))

      query.auction = this.auction ? this.auction.join(',') : ''

      var search = new URLSearchParams(query)
      this.$router.push('/auction?' + search)
      console.log(search)
    }
  }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>