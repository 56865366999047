<template>
  <Header></Header>
  <section class="page-header about-bg">
    <div class="container">
      <h1>О компании</h1>
    </div>
  </section>
  <section class="about-features">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <i class="about-feature-icon shield"></i>
          <p class="about-feature-text">Quis fringilla convallis et vitae volutpat at porttitor.</p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon id-card"></i>
          <p class="about-feature-text">Quis fringilla convallis et vitae volutpat at porttitor.</p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon car-key"></i>
          <p class="about-feature-text">Quis fringilla convallis et vitae volutpat at porttitor.</p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon tow-truck"></i>
          <p class="about-feature-text">Quis fringilla convallis et vitae volutpat at porttitor.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="about-v2">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-center">
          <img src="../assets/img/about-v2-image.png" class="about-image">
          <div class="our-experience d-none">
            <span>More Than</span>
            <h1>15</h1>
            <b class="mb-2">Years of</b>
            <b>Experience</b>
          </div>
        </div>
        <div class="col-md-6 about-text-wrap">
          <h1 class="title">О компании</h1>
          <p class="about-text"><b>Auto Canada KG</b> специализируется на предоставлении услуг по поиску, подбору и покупке подержанных автомобилей
            из Канады и США с последующей доставкой в Кыргызстан.</p>

          <p class="about-text">На нашем сайте вы можете найти весь автопарк автомобилей, выставленных на аукционы <b>Copart.com, Iaai.com,
            Copart.ca, Impactauto.ca</b>. Также у нас имеется прямой доступ к аукционам <b>Adesa</b> и <b>Manheim</b>. Кроме этого, мы
            закупаем автомобили у официальных дилеров и частных лиц по всей Канаде, чтобы предоставить вам еще больше
            вариантов выбора.</p>

          <p class="about-text">Наша компания предлагает два варианта сотрудничества.
            Первый вариант включает полное сопровождение клиента нашим опытным менеджером по продажам, который поможет вам
            выбрать автомобиль, проведет осмотр, организует покупку и обеспечит надежную доставку в Кыргызстан.</p>

          <p class="about-text">Второй вариант - самостоятельная покупка через наш сайт с использованием интерактивного портала доступа к
            онлайн-аукционам Канады и США. Для этого необходимо зарегистрироваться и получить уникальный логин и пароль,
            внести требуемый депозит, который дает право делать ставку до определенной суммы.</p>

          <p class="about-text">Мы работаем с проверенными и надежными партнерами, чтобы обеспечить высокое качество наших услуг и
            своевременную доставку автомобилей в Кыргызстан. Свяжитесь с нами сегодня, чтобы получить дополнительную
            информацию и помощь в выборе автомобиля, соответствующего вашим потребностям и бюджету.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="trust-us">
    <div class="container">
      <h1 class="title light">Почему нам доверяют?</h1>
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 shield"></i>
            <span class="about-feature-name">Гарантия</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 quality"></i>
            <span class="about-feature-name">Качество</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 clock2"></i>
            <span class="about-feature-name">Cкорость</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 experience"></i>
            <span class="about-feature-name">Опыт</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 service"></i>
            <span class="about-feature-name">Сервис</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 economy"></i>
            <span class="about-feature-name">Экономия</span>
            <p class="about-feature-text-v2">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt
              massa aliquam sed enim rhoncus.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about-us-v2">
    <div class="container">
      <div class="about-us-v2-title">Мы занимаемся подбором и поставкой подержанных автомашин из <span>США и Канады в Кыргызстан.</span>
      </div>
      <img src="../assets/img/about-us-v2.png" class="about-us-img-v2">
    </div>
  </section>
  <Partners></Partners>
  <Reviews></Reviews>
  <Footer class="border-top"></Footer>
</template>

<script>
import {useHead} from '@vueuse/head'
import Header from '@/components/Header.vue'
import Partners from '@/components/Partners.vue'
import Reviews from '@/components/Reviews.vue'
import Footer from '@/components/Footer.vue'

export default {
  setup() {
    useHead({
      title: 'О компании - AutoCanada',
    })
  },
  created() {
    document.getElementById('app').scrollIntoView({behavior: 'smooth'})
  },
  components: {
    Header,
    Partners,
    Reviews,
    Footer,
  }
}
</script>