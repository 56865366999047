<template>
  <div class="my-confirm-modal">
    <div class="my-background">
      <div class="btn-modal-close" @click="close('terms')"/>
      <div class="my-login-modal-content">
        <h2>Соглашение условий возврата гарантийного депозита</h2>
        <p>
          1. Стороны<br>
          1.1. Заказчик (Дилер) <br>
          1.2. Исполнитель: Autocanada.kg <br>
        </p>
        <p>
          2. Предмет соглашения <br>
          2.1. Настоящее соглашение регулирует условия возврата гарантийного депозита, который был внесен Заказчиком (Дилером),
          в рамках обеспечения гарантии покупки автомобиля.
        </p>
        <p>
          3. Запрос возврата депозита
          3.1.	Запрос возврата депозита направляется Заказчиком (Дилером) через официальный сайт, по электронной почте, факсу или обычной почте.
        </p>
        <p>
          4. Возврат депозита
          4.1. Депозит подлежит возврату Заказчику (Дилеру) в течение 14 рабочих дней с момента получения уведомления от
          Заказчика о желании вернуть депозит, ЗА ИСКЛЮЧЕНИЕМ СЛЕДУЮЩИХ СЛУЧАЕВ:
        </p>
        <p>
          4.1.1. В случае отказа Заказчика (Дилера) от уже выкупленного автомобиля. В этом случае депозит не подлежит возврату.
        </p>
        <p>
          4.1.2. В случае позднего уведомления Исполнителя Заказчиком (Дилером) об отказе от участия в аукционе. Заказчик
          (Дилер) должен направить уведомление об отказе от покупки автомобиля не менее чем за четыре часа до начала торгов.
          В этом случае депозит не подлежит возврату.
        </p>
        <p>
          4.1.3. В случае неуплаты Заказчиком (Дилером) полной стоимости автомобиля в течение 3 рабочих дней с момента получения инвойса.
          Если Заказчик не оплачивает в указанный срок полную стоимость автомобиля, депозит не подлежит возврату.
        </p>
        <p>
          4.1.4. В случае неуплаты Заказчиком (Дилером) вознаграждения Исполнителю за оказанные услуги.
          Если Заказчик не выполняет свою обязанность оплатить вознаграждение Исполнителю, депозит не подлежит возврату.
        </p>
        <p>
          4.1.5. В случае неуплаты Заказчиком (Дилером) всех дополнительных расходов, возникших по вине Заказчика (дилера).
          Если Заказчик не выполняет свою обязанность оплатить все дополнительные расходы, возникшие в результате его действий или
          бездействия, депозит не подлежит возврату.
        </p>
        <p>
          4.1.6. В случае неуплаты Заказчиком (Дилером) стоимости вынужденного простоя и хранения автомобиля на складах и
          территориях портов по пути его следования в конечный пункт. Если Заказчик не выполняет свою обязанность оплатить
          стоимость вынужденного простоя и хранения автомобиля, депозит не подлежит возврату.
        </p>
        <p>
          4.1.7. В случае неуплаты Заказчиком (Дилером) дополнительных расходов, возникших в период поставки автомобиля,
          включая повышение и изменение стоимости таможенных платежей, морского фрахта, железнодорожных тарифов, наземной
          транспортировки, изменение курсов валют, а также повышение и изменение других пошлин и сборов. Если Заказчик не
          выполняет свою обязанность оплатить дополнительные расходы, возникшие в период поставки, депозит не подлежит возврату.
        </p>
        <p>
          5. Расходы при возврате депозита.
          5.1. 	Все расходы, в том  числе комиссия банка при возврате депозита Покупателю путем банковского перевода на
          расчетный счет, на карту (VISA, MasterCard, Элсом м др.), на мобильные кошельки, а также на другие платежные
          системы будут вычтены из суммы депозита, подлежащей возврату. Если у Покупателя есть задолженности перед
          Продавцом, то эти суммы также будут вычтены из суммы депозита, подлежащей возврату. Оставшаяся сумма будет
          переведена на тот же на расчетный счет, на карту (VISA, MasterCard, Элсом м др.), на мобильные кошельки, а
          также на другие платежные системы Покупателя, с которого был получен депозит. Исполнитель не несет ответственности
          за возможные задержки или убытки, вызванные колебаниями обменного курса, банковскими сборами или ошибочными реквизитами,
          предоставленными Покупателем.
        </p>

      </div>

      <button type="button" class="custom-button blink-animate block" @click="close('terms', true)">
        Принять
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['close'],
};
</script>

<style scoped>
.my-login-modal-content {
  height: 800px;
  overflow: auto;
  padding: 40px 80px 120px;
}

.btn-modal-close {
  display: block!important;
  z-index: 9;
}

.my-background {
  position: relative;
  background: #fff;
}

.my-login-modal-content {
  background: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));
  mask-image: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
}

.my-login-modal-content::-webkit-scrollbar {
  width: 8px;
  background: #F5F4F6;
}

.my-login-modal-content::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
}

.my-login-modal-content::-webkit-scrollbar-thumb {
  background: #A02C12;
  border-radius: 10px;
}

.my-confirm-modal h2 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.my-confirm-modal p {
  font-size: 16px;
  line-height: 185%;
}

.my-confirm-modal p span {
  text-decoration: underline;
}

.my-background button {
  position: absolute;
  bottom: 30px;
  left: 80px;
  width: 80%;
}

@media screen and (max-width: 820px){
  .my-background {
    width: 80%;
  }

  .my-login-modal-content {
    width: 100%;
    height: 600px;
    margin-right: 0;
  }

  .my-confirm-modal h2 {
    font-size: 18px;
  }

  .my-confirm-modal .my-login-modal-content p {
    font-size: 12px;
    line-height: 16px;
  }

  .my-background button {
    bottom: 30px;
    left: 30px;
  }
}
</style>