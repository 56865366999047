import { createStore } from 'vuex'
import API from './api'
import Shared from './shared'
import Main from './main'
import Auction from './auction'
import Detail from './detail'
import Stock from './stock'
import User from './user'

const state = createStore({
    modules: {
        API,
        Shared,
        Main,
        Auction,
        Detail,
        Stock,
        User
    }
})

export default state;