<template>
    <section class="partners">
        <div class="container">
            <h1 class="title">Наши партнеры</h1>
            <div class="row d-flex partners-brand">
                <a v-for="(partner, index) in partners" :key="index" :href="partner.url" target="_blank" class="partner-brand-icon col-md-2 col-6">
                    <img :src="partner.image" v-if="partner.image" :alt="partner.title">
                </a>
                <div class="partner-brand-icon col-md-2 col-6"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        partners() {
            return (this.$store.getters.getPartners)
            ? this.$store.getters.getPartners
            : this.$store.dispatch('getPartners')
        },
    }
}
</script>