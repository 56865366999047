<template>
    <div class="overflow" v-if="modalStatus">
        <div class="close-modal" v-on:click="closeModal"></div>
        <div class="modal-window">
            <Form v-on:submit="sendContactForm" :validation-schema="validate" ref="form" class="contact-form" autocomplete="off">
                <h1>Заказать обратный звонок</h1>
                <div class="form-group">
                    <label>Номер телефона</label>
                    <Field name="phone" type="text" class="form-control rounded-0" v-model="phone" v-mask="phoneMask" placeholder="Введите ваш номер телефона" />
                    <div class="error-input-wrap">
                        <ErrorMessage class="error-wrap" name="phone" />
                    </div>
                </div>
                <div class="form-group">
                    <label>Электронная почта</label>
                    <Field name="email" type="email" class="form-control rounded-0" v-model="email" placeholder="Электронная почта" />
                    <div class="error-input-wrap">
                        <ErrorMessage class="error-wrap" name="email" />
                    </div>
                </div>
                <button type="submit" class="custom-button blink-animate block">Отправить</button>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'

export default {
    data() {
        return {
            email: '',
            phone: '',
            phoneMask: '+996 ### ### ###',
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        modalStatus() {
            return this.$store.getters.modalStatus
        },
        validate() {
            return Yup.object({
                email: Yup.string().required('Поле обязательное для заполнения').email('Введите E-mail'),
                phone: Yup.string().required('Поле обязательное для заполнения')
            })
        }
    },
    methods: {
        sendContactForm() {
            if(this.$refs.form.validate()) {
                const data = {
                    email: this.email,
                    phone: this.phone
                }
                this.$store.dispatch('feedBackForm', data)
                this.$store.dispatch('closeModal')
                this.phone = ''
                this.email = ''
            }
        },
        closeModal() {
            this.$store.dispatch('closeModal')
        }
    }
}
</script>