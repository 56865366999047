<template>
  <div class="my-modal" @click="close('passwordRecovery')">
    <div class="my-modal-content" @click.stop>
      <div class="btn-modal-close" @click="close('passwordRecovery-min')"/>
      <h2>Восстановление пароля</h2>
      <form v-if="show === 1" @submit.prevent="sendCode">
        <div class="my-modal-rows">
          <div class="my-modal-row">
            <select class="search-select" v-model="check">
              <option value="1">По телефону</option>
              <option value="2">По почте</option>
            </select>
          </div>
          <div class="my-modal-row" v-if="check === '1'">
            <input type="tel" id="phoneNumber" v-model="phone" @input="handleInput" @keydown="preventPlusSign" required placeholder="Номер телефона"/>
          </div>
          <div class="my-modal-row" v-if="check === '2'">
            <input type="email" id="email" v-model="email" required placeholder="Почта"/>
          </div>
          <div class="my-modal-row">
            <p>
              На данный номер телефона или почту будет отправлен код для восстановления пароля с дальнейшей инструкцией
            </p>
          </div>
        </div>
        <div class="button-row">
          <button class="custom-button blink-animate block">Восстановить пароль</button>
        </div>
      </form>

      <form v-if="show === 2" @submit.prevent="saveNewPassword">
        <div class="my-modal-rows">
          <div class="my-modal-row more-code">
            <p v-if="active !== 'hidden'">
              Не пришло сообщение? Можно <span :class="this.active" @click="moreCode">прислать ещё</span> <span v-if="timer">через {{ timer }} секунд</span>
            </p>
          </div>
          <div class="my-modal-row" v-if="check === '2'">
            <input type="email" id="email" v-model="email" required disabled/>
          </div>
          <div class="my-modal-row" v-if="check === '1'">
            <input type="tel" id="phoneNumber" v-model="phone" disabled/>
          </div>
          <div class="my-modal-row">
            <input type="text" id="code" v-model="code" required placeholder="Код"/>
          </div>
          <div class="my-modal-row">
            <input
                :type="[showPassword ? 'text' : 'password']"
                id="password"
                v-model="password"
                required
                placeholder="Новый пароль"
            />
            <span class="password-toggle" @click="togglePasswordVisibility">

            </span>
          </div>
        </div>

        <div class="button-row">
          <button class="custom-button blink-animate block">Сохранить новый пароль</button>
        </div>
      </form>

      <div class="another_links">
        <p>У вас есть профиль? <span @click="[openLogin(), close('passwordRecovery-all')]">Войти</span></p>
        <p>Нету профиля? <span @click="[openRegister(), close('passwordRecovery-all')]">Регистрация</span></p>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  </div>
</template>

<script>
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {auth} from "@/firebase";

export default {
  data() {
    return {
      show: 1,
      showPassword: false,
      email: '',
      code: '',
      password: '',
      check: '2',
      phone: '',
      confirmationResult: '',
      active: '',
      timer: 90,
    };
  },
  created() {
    const timer = setInterval(() => {
      this.timer = this.timer - 1
    }, 1000)

    setTimeout(() => {
      this.active = 'active-code'
      clearInterval(timer)
    }, 90000)
  },
  mounted() {
    try {
      this.appVerifier = new RecaptchaVerifier('recaptcha-container', {'size': 'invisible'}, auth);
    } catch (e) {
      console.log('captcha error', e, auth)
    }
  },
  props: ['close', 'openLogin', 'openRegister'],
  methods: {
    async sendCode() {
      if (this.check === '2') {
        await this.$store.dispatch('sendCode', { email: this.email, passwordRecovery: true })

        this.show = 2
      } else {
        const phoneNumber = this.phone;
        this.$store.commit('setLoading', true)

        await signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
            .then(confirmationResult => {
              // Код отправлен успешно
              this.confirmationResult = confirmationResult;
              this.$store.commit('setLoading', false)
              this.show = 2
            })
            .catch(error => {
              console.log(error)
              this.$store.commit('notification', {message: 'Восстановление по телефону номера временно не работает'})
              this.$store.commit('setLoading', false)
            });
      }
    },
    async saveNewPassword() {
      if (this.check === '1' && this.confirmationResult) {
        try {
          this.$store.commit('setLoading', true)
          await this.confirmationResult.confirm(this.code)
              .then(async result => {
                if (result.error) {
                  this.$store.commit('notification', {message: 'Неверный код'})
                  return
                }
                const idToken = await result.user.getIdToken(true)

                const data = {
                  phone: this.phone,
                  token: idToken,
                  password: this.password,
                }

                this.$store.dispatch('setNewPasswordPhone', data)

                this.confirmationResult = ''
                this.$store.commit('setLoading', false)
              })
        } catch (e) {
          this.$store.commit('notification', {message: 'Неверный код'})
          this.$store.commit('setLoading', false)
        }

        return
      }

      const data = {
        email: this.email,
        code: this.code,
        password: this.password,
      }

      this.$store.dispatch('setNewPassword', data)
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    preventPlusSign(event) {
      if (event.key === "+") {
        event.preventDefault();
      }
    },
    handleInput(event) {
      const inputValue = event.target.value;

      // Проверяем, есть ли уже символ "+", иначе добавляем его в начало значения
      if (!inputValue.includes('+')) {
        this.phone = '+' + inputValue;
      }
    },
    closeConfirmationModal() {
      this.confirmationResult = ''
    },
    moreCode() {
      this.active = 'hidden'
      if (this.check === '1') {

        const phoneNumber = this.phone;
        this.$store.commit('setLoading', true)

        signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
            .then(confirmationResult => {
              // Код отправлен успешно
              this.confirmationResult = confirmationResult;
              this.$store.commit('setLoading', false)
              this.$store.commit('notification', {message: 'Код отправлен на ваш номер телефона', type: 'success'})
            })
            .catch(error => {
              console.log(error)
              this.$store.commit('setLoading', false)
              this.$store.commit('notification', {message: 'Повторная отправка кода не сработала'})
            });

        return
      }

      this.$store.dispatch('sendCode', { email: this.email, passwordRecovery: true })
    }
  }
};
</script>

<style scoped>
.my-modal-rows {
  display: flex;
  flex-direction: column;
}

.my-modal-row {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto 10px;
}

.my-modal .my-modal-content .more-code span {
  pointer-events: none;
}

.my-modal .my-modal-content .more-code .active-code {
  pointer-events: auto!important;
  cursor: pointer;
  color: #A02C12;
}

.my-modal-row p {
  color: #767676;
  text-align: center;
  font-size: 18px;
  line-height: 180%;
}

.my-modal-row label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1E1E1E;
  opacity: 0.8;
}

.my-modal-row input {
  width: 100%;
  padding: 15px 20px;
}

.button-row {
  max-width: 430px;
  margin: 50px auto;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 820px){
  .my-modal-rows {
    flex-direction: column;
    height: auto;
  }

  .btn-modal-close {
    right: 15px;
    top: 15px;
  }

  .my-modal-row {
    width: 100%;
  }

  .my-modal-row p {
    font-size: 12px;
  }

  .input-row {
    width: 100%;
  }

  .button-row {
    margin: 20px auto 30px;
  }
}
</style>