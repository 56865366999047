<template>
  <div class="history-container">
    <p class="history-title"><span>Внести Депозит</span><span>История операций</span></p>
    <div class="history-data-block">
      <table>
        <thead>
        <tr>
          <th>Дата</th>
          <th>Тип операции</th>
          <th>Приход</th>
          <th>Расход</th>
          <th>Платеж.система</th>
          <th>Статус</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in history?.results" :key="item">
          <td>{{ item.created_at }}</td>
          <td>
            {{ item.type_operation === 'bid' ? 'Ставка'
              : (item.type_operation === 'replenishment' ? 'Пополнение' : 'Снятие')
            }}
          </td>
          <td>{{ item.arrival ? `${item.arrival} $` : '-' }}</td>
          <td>{{ item.spending ? `${item.spending} $` : '-' }}</td>
          <td><img :src="item.payment_system ? item.payment_system : default_img" alt="payment"></td>
          <td>
            <img :src="item.status === 'approved'
            ? approved : (item.status === 'rejected'
            ? rejected : pending)" alt="status">
          </td>
        </tr>
        </tbody>
      </table>
      <div class="pag-list" v-if="history.previous !== null || history.next !== null">
        <a class="pag-link pag-arrow-left" :class="history.previous === null && 'disabled'" v-on:click="pagination(history.previous)">Предыдущая</a>
        <a class="pag-link pag-arrow-right" :class="history.next === null && 'disabled'" v-on:click="pagination(history.next)">Следующая</a>
      </div>
      <div class="box-wrap" v-if="!history?.results?.length">
        <h3 class="wrap-title text-center">Ничего не найдено</h3>
      </div>
    </div>
  </div>
</template>

<script>
import img_bid from '../assets/img/icons/img-bid.png'
import rejected from '../assets/img/icons/close-circle.png'
import approved from '../assets/img/icons/tick-circle.png'
import pending from '../assets/img/icons/timer.png'

  export default {
    data() {
      return {
        default_img: img_bid,
        rejected: rejected,
        approved: approved,
        pending: pending
      }
    },
    created() {
      this.$store.dispatch('getHistory')
    },
    computed: {
      history() {
        return this.$store.getters.getHistory
      }
    },
    methods: {
      pagination(url) {
        this.$store.dispatch('getHistory', url)
      }
    }
  }
</script>

<style scoped>
.history-container {
  max-width: 1240px;
  padding: 0 15px;
  margin: -40px auto 150px;
}

.history-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 50px;
}

.history-title span:last-child {
  color: #7B7979;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #1E1E1E;
}

.history-data-block {
  overflow: auto;
  background: #FFFFFF;
  box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
  border-radius: 5px;
  padding: 35px;
}

.history-data-block::-webkit-scrollbar {
  height: 7px;
  background: #D9D9D9;
  border-radius: 3px;
}

.history-data-block::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
}

.history-data-block::-webkit-scrollbar-thumb {
  background: #A02C12;
  border-radius: 3px;
}

.history-data-block table {
  width: 100%;
  min-width: 900px;
}

.history-data-block th {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #7B7979;
  padding-bottom: 20px;
}

.history-data-block td {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 20px;
}

.history-data-block td:nth-child(5) img {
  width: 80px;
}

.history-data-block td:nth-child(6) img {
  width: 30px;
}

.history-data-block tr td:nth-child(2) {
  text-decoration: underline;
}

.history-data-block tr td, .history-data-block tr th {
  text-align: center;
}

.history-data-block tr td:nth-child(-n+3), .history-data-block tr th:nth-child(-n+3) {
  text-align: left;
}

@media screen and (max-width: 1280px){
  .history-data-block th {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 14px;
  }

  .history-data-block td {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 14px;
  }

  .history-data-block td:nth-child(5) img {
    width: 50px;
  }

  .history-data-block td:nth-child(6) img {
    width: 20px;
  }
}
</style>