import { createWebHashHistory, createRouter } from 'vue-router'
import store from '../store'
import Home from '@/pages/Home'
import About from '@/pages/About'
// import Services from '@/pages/Services'
import Contacts from '@/pages/Contacts'
import Auction from '@/pages/Auction'
import Marks from '@/pages/Marks'
import Detail from '@/pages/Detail'
import Calculator from '@/pages/Calculator'
import Stock from '@/pages/Stock'
import StockDetail from '@/pages/DetailStock'
import Profile from "@/pages/Profile.vue";
import ForClient from "@/pages/ForClient.vue";

const requireAuth = async (to, from, next) => {
    let user = store.getters.getUser
    if (!user) {
        await store.dispatch('getUserDetail', 'user').then(r => console.log(r))
        user = store.getters.getUser
    }

    if (user) {
        next()
    } else {
        next('/')
        store.commit('notification', {message: 'У вас нету доступа!'})
    }
}

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home
    },
    {
        name: 'Page not found',
        path: '/:pathMatch(.*)*',
        component: Home
    },
    {
        name: 'About',
        path: '/about',
        component: About
    },
    // {
    //     name: 'Services',
    //     path: '/services',
    //     component: Services
    // },
    {
        name: 'Contacts',
        path: '/contacts',
        component: Contacts
    },
    {
        name: 'Marks',
        path: '/marks',
        component: Marks
    },
    {
        name: 'Auction',
        path: '/auction',
        component: Auction
    },
    {
        name: 'Detail',
        path: '/detail/:id',
        component: Detail
    },
    {
        name: 'Calculator',
        path: '/calculator',
        component: Calculator
    },
    {
        name: 'Stock',
        path: '/stock',
        component: Stock
    },
    {
        name: 'Stock Detail',
        path: '/stock/detail/:id',
        component: StockDetail
    },
    {
        name: 'User Detail',
        path: '/user',
        component: Profile,
        beforeEnter: requireAuth
    },
    {
        name: 'For client',
        path: '/data',
        component: ForClient
    },
]

const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),
    routes
})

export default router;