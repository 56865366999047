<template>
  <div class="container my-favorite-cars-con">
    <div class="my-favorite-cars">
      <div class="car-card" v-for="car in cars.results" :key="car">
        <div class="car-thumbnail car-thumbnail-custom">
          <span :class="`favorite-btn${car.favorite ? ' favorite-btn-active' : ''}`" @click="addFavorite(car.id)"></span>
          <img :src="car.image" :alt="car.brand + ' ' + car.year" v-if="car.image">
        </div>
        <div class="car-body">
          <h3 class="car-title">{{ car.brand }}</h3>
          <span class="car-auction-date">Дата аукциона: <b>{{ String(car.auction_date).replace(' ', 'г.,') + 'ч.' }}</b></span>
          <span class="car-auction-date">Цена: <b>${{ car.price }}</b></span>
          <div class="row car-info">
            <div class="col-md-6 col-6">
              <i class="mileage"></i>
              <b>{{ car.mileage ? car.mileage : '0' }} км</b>
              <span>Пробег</span>
            </div>
            <div class="col-md-6 col-6">
              <i class="year"></i>
              <b>{{ car.year ? car.year : '-' }}</b>
              <span>Год</span>
            </div>
          </div>
          <div class="row car-info pb-2">
            <div class="col-md-6 col-6">
              <i class="transmission"></i>
              <b>{{ car.transmission ? car.transmission : '-' }}</b>
              <span>КПП</span>
            </div>
            <div class="col-md-6 col-6">
              <i class="fuel"></i>
              <b>{{ car.fuel ? car.fuel : '-' }}</b>
              <span>Двигатель</span>
            </div>
          </div>
          <span class="car-auction-date">VIN: <b>${{ car.vin }}</b></span>
          <router-link :to="'/detail/' + car.id" class="car-btn">Подробнее</router-link>
        </div>
      </div>
    </div>
    <div class="pag-list" v-if="cars?.previous !== null || cars?.next !== null">
      <a class="pag-link pag-arrow-left" :class="cars.previous === null && 'disabled'" v-on:click="pagination(cars.previous)">Предыдущая</a>
      <a class="pag-link pag-arrow-right" :class="cars.next === null && 'disabled'" v-on:click="pagination(cars.next)">Следующая</a>
    </div>
    <div class="box-wrap" v-if="!cars?.results?.length">
      <h3 class="wrap-title text-center">Ничего не найдено</h3>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('getFavoriteCars')
  },
  computed: {
    cars() {
      return this.$store.getters.getFavoriteCars
    }
  },
  methods: {
    pagination(url) {
      this.$store.dispatch('getFavoriteCars', url)
    },
    async addFavorite(id) {
      await this.$store.dispatch('addFavoriteCar', {car_id: id})
      await this.$store.dispatch('getFavoriteCars')

      const message = this.$store.getters.getFavoriteMessage

      if (message === 'deleted') {
        this.$store.commit('notification', {message: 'Машина удалена из избранных!', type: 'success'})
      } else if (message === 'added') {
        this.$store.commit('notification', {message: 'Машина добавлена в избранное!', type: 'success'})
      }
    },
  }
}
</script>


<style scoped>
.my-favorite-cars-con {
  margin-bottom: 200px;
}

.my-favorite-cars {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  row-gap: 30px;
  column-gap: 10px;
  margin-bottom: 30px;
}

.my-favorite-cars .car-card {
  width: 320px;
}

.car-thumbnail-custom {
  position: relative;
}

.car-thumbnail-custom img {
  margin: 0;
}

.car-thumbnail-custom .favorite-btn {
  top: 20px;
  right: 18px;
  width: 46px;
  height: 46px;
}

@media screen and (max-width: 720px){
  .my-favorite-cars {
    justify-content: center;
  }
}
</style>