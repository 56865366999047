<template>
    <Header class="light" theme="light"></Header>
    <section class="calculator">
        <div class="container">
            <h3 class="wrap-title text-center">Калькулятор расчета стоимости авто</h3>
            <div class="row pt-4">
                <div class="col-md">
                    <Form v-on:submit="calculate" :validation-schema="validateMain" autocomplete="off" class="box-wrap mb-4">
                        <div class="row">
                            <div class="col-md-3 col-6">
                                <label class="form-label">Цена авто</label>
                                <Field name="bid" type="number" class="form-input mb-0" v-model="bid" />
                                <div class="error-input-wrap">
                                    <ErrorMessage class="error-wrap" name="bid" />
                                </div>
                            </div>
                          <div class="col-md-3 col-6 country-check">
                            <div class="country-check-in">
                              <span @click="check('USA')" :class="`usa${this.currency === 'USA' ? ' check-active' : ''}`">$</span>
                              <span @click="check('CAD')" :class="`canada${this.currency === 'CAD' ? ' check-active' : ''}`">$</span>
                            </div>
                          </div>
                            <div class="col-md-6">
                                <label class="form-label">Кузов</label>
                                <Field name="body" as="select" class="form-input mb-0" v-model="body">
                                    <template v-for="body in bodyList" :key="body">
                                        <option :value="body.id">{{ body.title }}</option>
                                    </template>
                                </Field>
                                <div class="error-input-wrap">
                                    <ErrorMessage class="error-wrap" name="body" />
                                </div>
                            </div>
                        </div>
                        <label class="form-label">Выберите аукцион</label>
                        <Field name="auction" as="select" class="form-input mb-0" v-model="auction">
                            <template v-for="auction in auctions" :key="auction">
                                <option :value="auction.id">{{ auction.title }}</option>
                            </template>
                        </Field>
                        <div class="error-input-wrap">
                            <ErrorMessage class="error-wrap" name="auction" />
                        </div>
                        <label class="form-label">Местоположение аукциона</label>
                        <Field name="city" as="select" class="form-input mb-0" v-model="city">
                            <option value="0">---</option>
                            <template v-for="city in auctionCities" :key="city">
                                <option :value="city.id +':'+ city.zip_code +':'+ city.location">{{ city.location }}</option>
                            </template>
                        </Field>
                        <div class="error-input-wrap">
                            <ErrorMessage class="error-wrap" name="city" />
                        </div>
                        <label class="form-label">Отправка из порта</label>
                        <select class="form-input" v-model="portCanada">
                            <option value="0">---</option>
                            <template v-for="port in portCanadaList" :key="port">
                                <option :value="port.id">{{ port.title || 'Не найдено' +' - $'+ port.price || 'Не назначено' }}</option>
                            </template>
                        </select>
                        <label class="form-label">Cтрана назначения</label>
                        <select class="form-input" v-model="countryDestination">
                            <template v-for="country in countryDestinationList" :key="country">
                                <option :value="country.id">{{ country.title }}</option>
                            </template>
                        </select>
                        <label class="form-label">Порт назначения</label>
                        <select class="form-input" v-model="port">
                            <template v-for="port in portDestinationList" :key="port">
                                <option :value="port.id">{{ port.title }}</option>
                            </template>
                        </select>
                    </Form>
                </div>
                <div class="col-md">
                    <div class="box-wrap mb-4">
                        <div class="report-info justify-content-between">
                            <b>Стоимость авто</b>
                            <span>USD {{ calculator.car_price ? calculator.car_price : '0' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <b>Аукционный сбор</b>
                            <span>USD {{ calculator.auction_fee ? calculator.auction_fee : '0' }}</span>
                        </div>
                        <div
                            class="report-info justify-content-between"
                            v-if="calculator.canada_sales_tax || calculator.canada_sales_tax === 0"
                        >
                            <b style="gap: 0">Налог (
                              <span class="text-danger logo-in-text">
                                покрывается AutoCanada
                              </span>)
                            </b>
                            <span class="text-underline">USD {{ ((calculator.car_price + calculator.auction_fee) * 0.13).toFixed(0) }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <b>Экспортное оформление</b>
                            <span>USD {{ calculator.paperwork_fee ? calculator.paperwork_fee : '0' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <b>Доставка в порт</b>
                            <span>USD {{ calculator.delivery ? calculator.delivery : '0' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <b>ПЕРЕВОЗКА <span style="text-transform: lowercase">(морская, наземная)</span></b>
                            <span>USD {{ calculator.transportation ? calculator.transportation : '0' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <div class="tool-tip">
                                <p class="text-center">Cтраховка автомобиля (1.5%)**</p>
                            </div>
                            <b class="text-danger"><div class="radio-btn" :class="containerInsurance && 'active'" v-on:click="containerInsurance = !containerInsurance"></div><u>С учётом страховки</u></b>
                            <span>{{ (calculator && containerInsurance) ? 'USD ' + calculator.container_insurance : '---' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <div class="tool-tip">
                                <p class="text-center">Сумма таможенных  платежей может отличаться на момент таможенного оформления.</p>
                            </div>
                            <b class="text-danger"><div class="radio-btn" :class="custom && 'active'" v-on:click="custom = !custom"></div><u v-on:click="showCalculator" class="cursor-help">С учётом таможни</u></b>
                            <span>{{ (calculator && custom) ? 'USD ' + calculator.custom : '---' }}</span>
                        </div>
                        <div class="report-info justify-content-between">
                            <b>Услуга <span class="text-danger">Auto Canada KG</span></b>
                            <span>USD {{ calculator.service_auto_canada ? calculator.service_auto_canada : '0' }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="box-wrap mb-4">
                            <p class="text-center mb-0" v-if="deliveryCountry">Для получения информации, прошу обраться в службу поддержки</p>
                            <h3 class="wrap-title" v-else>Итоговая стоимость авто: <b>USD {{ (calculator.total && calculator.total > 0) ? calculator.total.toFixed(2) : '0' }}</b></h3>
                        </div>
                        <div class="box-wrap mb-4" v-if="!deliveryCountry">
                            <p class="text-center mb-0" v-if="deliveryCountry"></p>
                            <h3 class="wrap-title" v-else>Обратный калькулятор: <b>USD</b> <Field name="total" type="number" class="form-input mb-0 w-50" v-model="total" /></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <ReverseCalculator/> -->
    <Contacts></Contacts>
    <Footer></Footer>
    <div class="overflow" v-if="calculatorStatus">
        <div class="close-modal" v-on:click="closeCalculator"></div>
        <div class="modal-window white">
            <Form v-on:submit="onSubmit" :validation-schema="validateCar" autocomplete="off">
                <h3>Расчет таможенных платежей</h3>
                <div class="form-group pb-3">
                    <label>Год выпуска авто</label>
                    <Field name="year" as="select" class="form-control" v-model="year">
                        <option v-for="i in 24" :key="i" :value="years(i)">{{ years(i) }}</option>
                    </Field>
                    <div class="error-input-wrap">
                        <ErrorMessage class="error-wrap" name="year" />
                    </div>
                </div>
                <div class="form-group pb-3">
                    <label>Тип двигателя</label>
                    <Field name="fuel" as="select" class="form-control" v-model="fuel">
                        <option v-for="fuel in fuels" :key="fuel" :value="fuel.value">{{ fuel.name }}</option>
                    </Field>
                    <div class="error-input-wrap">
                        <ErrorMessage class="error-wrap" name="fuel" />
                    </div>
                </div>
                <div class="form-group pb-3">
                    <label>Объем двигателя, куб. л. (например 2.0)</label>
                    <Field
                        name="volume"
                        type="number"
                        class="form-control"
                        @input="inputVolume"
                        v-model="volume"
                        @keydown.up="increment"
                        @keydown.down="decrement"
                        step="0.1"
                    />
                    <div class="error-input-wrap">
                        <ErrorMessage class="error-wrap" name="volume" />
                    </div>
                </div>
                <div class="form-group pb-4">
                    <label>Итого</label>
                    <input type="text" class="form-control" :value="calculator.custom" disabled="disabled">
                </div>
                <button type="submit" class="custom-button blink-animate block">Подсчитать</button>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import {ref} from "vue";
import * as yup from 'yup'
import Header from '@/components/Header.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'
// import ReverseCalculator from '@/components/ReverseCalculator.vue';

export default {
    data() {
        return {
            yearMin: 1999,
            yearMax: new Date().getFullYear() + 1,
            fuels: [
                {name: 'Бензин', value: 'Flexible Fuel', slug: 'Flexible_Fuel'},
                {name: 'Дизель', value: 'Diesel', slug: 'Diesel'},
                {name: 'Газ', value: 'Gasoline', slug: 'Gasoline'},
                {name: 'Гибрид', value: 'Hybrid', slug: 'Hybrid'},
                {name: 'Электрический', value: 'Electric', slug: 'Electric'},
                {name: 'Другое', value: 'Other', slug: 'Other'}
            ],
            auction: '',
            city: 0,
            year: '',
            volume: '',
            fuel: '',
            body: 1,
            bid: 1000,
            containerInsurance: false,
            custom: false,
            countryDestination: 1,
            port: 1,
            portCanada: 0,
            zip_code: 1,
            location: '',
            calculatorStatus: false,
            calculatorVolume: '',
            calculatorYear: '',
            deliveryCountry: false,
            currency: 'CAD',
            total: 0
        }
    },
    setup() {
      const value = ref(0.1);

      const increment = () => {
        value.value += 0.1;
      };

      const decrement = () => {
        value.value -= 0.1;
      };

      return {
        value,
        increment,
        decrement
      };
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        Header,
        Contacts,
        Footer,
        // ReverseCalculator
    },
    computed: {
        calculator() {
            return this.$store.getters.getCalculateResult
        },
        auctions() {
            return (this.$store.getters.getAuctionList)
            ? this.$store.getters.getAuctionList
            : this.$store.dispatch('getAuctionList')
        },
        auctionCities() {
            return this.$store.getters.getCityList
        },
        bodyList() {
            return (this.$store.getters.getCalculatorBodyList)
            ? this.$store.getters.getCalculatorBodyList
            : this.$store.dispatch('getCalculatorBodyList')
        },
        countryDestinationList() {
            return (this.$store.getters.getCountryDestination)
            ? this.$store.getters.getCountryDestination
            : this.$store.dispatch('getCountryDestination')
        },
        portDestinationList() {
            return (this.$store.getters.getPortDestination)
            ? this.$store.getters.getPortDestination
            : this.$store.dispatch('getPortDestination')
        },
        portCanadaList() {
            const port = this.$store.getters.getPortCanada
            if(port.length == 0){
                return 0
            }
            else{
                return port
            }
        },
        validateMain() {
            return yup.object({
                bid: yup.string().required('Поле обязательное для заполнения'),
                body: yup.string().required('Выберите кузов'),
                auction: yup.string().required('Выберите аукцион'),
                city: yup.string().required('Выберите город')
            })
        },
        validateCar() {
            return yup.object({
                year: yup.string().required('Выберите год'),
                fuel: yup.string().required('Выберите тип двигателя'),
                volume: yup.number().required('Поле обязательное для заполнения')
            })
        }
    },
    watch: {
        custom() {
            if(this.calculator) {
                if(!this.custom) {
                    this.calculator.total = this.calculator.total - this.calculator.custom
                } else {
                    this.calculator.total = this.calculator.total + + this.calculator.custom
                }
            }
        },
        containerInsurance() {
            if(this.calculator) {
                if(!this.containerInsurance) {
                    this.calculator.total = this.calculator.total - this.calculator.container_insurance
                } else {
                    this.calculator.total = this.calculator.total + + this.calculator.container_insurance
                }
            }
        },
        auctions() {
            this.auction = this.$store.getters.getAuctionList[0].id
        },
        bodyList() {
            this.body = this.$store.getters.getCalculatorBodyList[0].id
        },
        calculator() {
            this.calculator = this.$store.getters.getCalculateResult
            if(this.custom) {
                this.calculator.total = this.calculator.total + + this.calculator.custom
            }
            if(this.containerInsurance) {
                this.calculator.total = this.calculator.total + + this.calculator.container_insurance
            }
        },
        bid() {
            this.calculate()
        },
        body() {
            this.calculate()
        },
        auction() {
            this.$store.dispatch('getCityList', this.auction)
            this.calculate()
        },
        total(){
            this.reverseCalculate()
        },
        city() {
            var value = String(this.city).split(':')
            this.location = value[0]
            const data = new URLSearchParams({
                auction: this.auction,
                zip_code: value[1],
                location: value[2],
                body: this.body,
            })
            this.$store.dispatch('getPortCanada', data)
            this.calculate()
        },
        countryDestination() {
            if(this.countryDestination === 1) {
                this.deliveryCountry = false
                this.calculate()
            } else {
                this.deliveryCountry = true
            }
        },
        port() {
            this.calculate()
        },
        portCanada() {
            this.calculate()
        },
        auctionCities() {
            // this.city = this.$store.getters.getCityList[0].id +':'+ this.$store.getters.getCityList[0].zip_code +':'+ this.$store.getters.getCityList[0].location
            this.location = this.$store.getters.getCityList[0]?.id
        },
        portCanadaList() {
            const port = this.$store.getters.getPortCanada
            if(port.length == 0){
                this.portCanada = 0
            }
            else{
                this.portCanada = this.$store.getters?.getPortCanada[0]?.id
            }
        }
    },
    methods: {
        years(count) {
            return this.yearMax - count
        },
        calculate() {
            const data = {
                auction: this.auction,
                city: this.location,
                price: this.bid,
                year: this.year ? this.year : null,
                volume: this.volume ? this.volume : null,
                fuel: this.fuel,
                body: this.body,
                'port_canada': this.portCanada,
                'port_destination': this.port,
                'country_destination': this.countryDestination,
                currency: this.currency,
            }
            this.$store.dispatch('calculate', data)
        },
        reverseCalculate() {
            const data = {
                auction: this.auction,
                city: this.location,
                price: this.bid,
                year: this.year ? this.year : null,
                volume: this.volume ? this.volume : null,
                fuel: this.fuel,
                body: this.body,
                'port_canada': this.portCanada,
                'port_destination': this.port,
                'country_destination': this.countryDestination,
                currency: this.currency,
                total: this.total,
            }
            this.$store.dispatch('reverseCalculate', data)
        },
        onSubmit() {
            const data = {
                auction: this.auction,
                city: this.location,
                price: this.bid,
                year: this.year ? this.year : null,
                volume: this.volume ? this.volume : null,
                fuel: this.fuel,
                body: this.body,
                'port_canada': this.portCanada,
                'port_destination': this.port,
                'country_destination': this.countryDestination,
                currency: this.currency,
            }
            this.$store.dispatch('calculate', data)
            this.calculator = this.$store.getters.getCalculateResult
        },
        showCalculator() {
            this.calculatorStatus = true
        },
        closeCalculator() {
            this.calculatorStatus = false
        },
        check(value) {
            this.currency = value
            this.calculate()
        },
        inputVolume(e) {
            if (e.target.value === '') {
              this.volume = 0;
            }
        },
    }
}
</script>

<style scoped>
  .logo-in-text {
    text-transform: none!important;
  }
</style>