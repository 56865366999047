<template>
  <div>
    <div v-if="!isImageExpanded" class="thumbnail-container">
      <div v-for="(image, index) in images" :key="index" class="thumbnail-wrapper">
        <img
            :src="image"
            :alt="'Image ' + (index + 1)"
            @click="expandImage(index)"
        />
      </div>
    </div>

    <div v-else class="expanded-image-container">
      <div class="slider-next-btn" v-on:click="changeImage('next')"></div>
      <div class="slider-prev-btn" v-on:click="changeImage('prev')"></div>
      <div class="expanded-image-container-bg" @click="shrinkImage">
        <img :src="images[expandedImageIndex]" :alt="'Image ' + (expandedImageIndex + 1)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isImageExpanded: false,
      expandedImageIndex: 0,
    };
  },
  props: ['images'],
  methods: {
    expandImage(index) {
      this.isImageExpanded = true;
      this.expandedImageIndex = index;
    },
    shrinkImage() {
      this.isImageExpanded = false;
    },
    changeImage(value) {
      if (value === 'next') {
        if (this.images?.length - 1 === this.expandedImageIndex) {
          return
        }
        this.expandedImageIndex = this.expandedImageIndex + 1
      } else if (value === 'prev') {
        if (this.expandedImageIndex === 0) {
          return
        }
        this.expandedImageIndex = this.expandedImageIndex - 1
      }
    }
  },
};
</script>

<style scoped>
.thumbnail-container {
  display: flex;
  max-width: 882px;
}

.thumbnail-wrapper {
  width: 160px;
}

.thumbnail-container img {
  width: 100%;
  height: auto;
}

.thumbnail-wrapper {
  margin: 5px; /* Add some margin between thumbnails */
}

.expanded-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.expanded-image-container-bg {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.expanded-image-container img {
  height: 80%;
  object-fit: cover;
  border-radius: 19px;
}
</style>