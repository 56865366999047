<template>
  <div class="container">
    <div class="bank-description">
      <p>
        Для того чтобы отослать нам банковский перевод, пожалуйста, распечатайте эту форму и отнесите её в банк.
        Пожалуйста, учитывайте. Что вам придётся совершить банковский перевод в валюте США (доллар). Даже если у
        вас нет долларового счёта в банке, ваш банк сможет конвертировать вашу валюту в доллары США и совершить
        электронный перевод платежа. Пожалуйста указывайте ваш ДИЛЕРСКИЙ НОМЕР AUTO CANADA KG в назначении платежа
        при отправке банковских переводов нашей компании.
      </p>
    </div>
    <div class="bank-info">
      <p>
        <span>Primary Bank account:</span>
        <span></span>
      </p>
      <p>
        <span>Account name:</span>
        <span>ALABEL AUTO EXPORT</span>
      </p>
      <p>
        <span>Bank name:</span>
        <span>THE BANK OF NOVA SCOTIA</span>
      </p>
      <p>
        <span>Bank Address:</span>
        <span>1427 Carling Avenue, Ottawa, Ontario, K1Z 7L6, Canada</span>
      </p>
      <p>
        <span>Swift Number/BIC:</span>
        <span>NOSCCATT</span>
      </p>
      <p>
        <span>Institution:</span>
        <span>002</span>
      </p>
      <p>
        <span>USD Account Number:</span>
        <span>70482 01344 14</span>
      </p>
      <p>
        <span>Canadian Clearing Code:</span>
        <span>CC0002 20396</span>
      </p>
    </div>
    <p class="bank-warning">
      *Пожалуйста, не забудьте выслать нам электронное письмо с подтверждением того, что вы совершили платёж.
      <br> *Пожалуйста обратите внимание, что с международных платежей промежуточным банком может быть снято 20$ комиссии
      за транзит денег.
      <br> *Пожалуйста в назначении платежа укажите - "Депозит на покупку авто и Ваш дилерский номер"
    </p>
  </div>
</template>

<script>

</script>


<style scoped>
.bank-description {
  background: #FFFFFF;
  box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
  border-radius: 5px;
  padding: 16px 40px;
}

.bank-description p {
  font-weight: 400;
  font-size: 18px;
  line-height: 185%;
  margin: 0;
}

.bank-description span {
  font-weight: 500;
}

.bank-info {
  max-width: 670px;
  margin: 0 auto;
}

.bank-info p {
  margin: 0;
  padding: 14px 0;
  display: flex;
  gap: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.bank-info p:nth-child(2n) {
  background: rgba(30, 30, 30, 0.05);
  border-radius: 2px;
}

.bank-info p:nth-child(1) span:first-child {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.bank-info p span:first-child {
  width: 46%;
  text-align: right;
}

.bank-info p span:last-child {
  width: 30%;
  text-align: left;
  color: #115B7D;
}

.bank-warning {
  max-width: 670px;
  font-weight: 400;
  font-size: 16px;
  line-height: 185%;
  color: rgba(255, 0, 0, 0.83);
  margin: 12px auto 240px;
  padding-right: 70px;
}
</style>