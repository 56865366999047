<template>
  <div class="my-confirm-modal">
    <div class="my-background">
      <div class="btn-modal-close" @click="close('policy')"/>
      <div class="my-login-modal-content">
        <h2>Политика конфиденциальности</h2>
        <p>
          Мы понимаем важность конфиденциальности информации, предоставляемой пользователями нашего веб-сайта <span>autocanada</span> .
          Наша политика основана на принципах уважения к правам на конфиденциальность наших пользователей. При
          использовании нашего веб-сайта, <span>autocanada</span>, мы призываем вас внимательно ознакомиться и согласиться со всеми
          условиями и положениями нашей политики конфиденциальности.

          Сбор информации
          На нашем веб-сайте <span>autocanada</span> мы запрашиваем определенную личную информацию при регистрации для создания учетной
          записи, такую как адрес проживания, адрес электронной почты и номер телефона. Мы собираем информацию о
          пользователях во время регистрации, покупки автомобилей и в редких случаях. Наша политика заключается в том,
          чтобы собирать только ту личную информацию, которую пользователи осознанно и специально предоставляют нам. При
          посещении веб-сайта AutoCanada KG мы получаем информацию о посещении, а не о пользователе лично. Мы используем
          данные из журналов веб-сервера и файлов cookie браузера для отслеживания статистических показателей, таких как
          количество посетителей, просматриваемые страницы, домены, с которых приходят наши посетители, и типы
          используемых браузеров.
          Мы обрабатываем собранную информацию анонимно, не связывая ее с вами как отдельным лицом. Эти статистические
          данные используются для улучшения производительности и удобства нашего веб-сайта для вас и других посетителей.
          AutoCanada KG принимает разумные меры безопасности для защиты, предоставленной нам личной информации и не
          раскрывает ее третьим лицам без вашего явного согласия. Мы используем собранную информацию исключительно внутри
          компании AutoCanada KG. Любая информация, собранная в процессе регистрации, не будет передаваться или
          продаваться третьим сторонам, за исключением случаев, когда мы обязаны сделать это в соответствии с законным
          запросом, например, судебным решением.

          Cookies
          Cookie - это небольшой файл, который AutoCanada KG передает на жесткий диск вашего компьютера и содержит
          уникальный идентификационный номер. Он позволяет нам распознавать вас как уникального пользователя и улучшать
          ваш опыт использования нашего веб-сайта. Cookie предоставляют нам анонимную информацию, такую как ваш
          интернет-провайдер, браузер и некоторые онлайн-тенденции и привычки. Использование cookie является общепринятой
          практикой в индустрии и широко применяется на большинстве крупных веб-сайтов. Большинство браузеров изначально
          настроены на принятие cookie, но вы можете изменить настройки вашего браузера, чтобы получать уведомления о
          получении cookie или отказываться от их использования. Однако, имейте в виду, что отключение cookie может
          привести к некорректной работе некоторых функций. Пожалуйста, обратите внимание, что мы не будем раскрывать
          информацию, полученную через наши cookie, если это не требуется по закону.

          Безопасность
          AutoCanada KG придает большое значение безопасности личной информации наших пользователей. Для обеспечения
          безопасности транзакций на нашем веб-сайте мы используем программное обеспечение Secure Socket Layer (SSL),
          которое шифрует вводимые данные. Мы принимаем меры безопасности, чтобы предотвратить потерю, незаконное
          использование и изменение личной информации, которая находится под нашим контролем. Наша политика безопасности и
          конфиденциальности регулярно обновляется и улучшается при необходимости, а доступ к информации пользователей
          предоставляется только уполномоченным сотрудникам. Несмотря на то, что мы не можем гарантировать полную защиту
          от потери, незаконного использования или изменения информации, мы предпринимаем все разумные меры для
          предотвращения таких ситуаций. Однако следует помнить, что передача информации через интернет не является
          полностью безопасной. Мы не можем гарантировать безопасность информации, передаваемой через наш сайт, и такая
          передача осуществляется на ваш риск.

          Для предотвращения несанкционированного доступа к вашей личной информации, особенно если вы используете общий
          компьютер или компьютер в общественном месте, рекомендуется закрыть браузер после окончания пользовательской
          сессии. Мы также принимаем меры безопасности для защиты всей личной и анонимной информации от
          несанкционированного доступа.

          Адреса электронной почты
          При предоставлении вашего адреса электронной почты, мы можем отправлять вам информационные письма о нашем
          веб-сайте и наших услугах. Также, время от времени, мы будем рассылать вам электронные письма, в которых может
          содержаться информация о специальных акционных предложениях и других интересных возможностях.

          Удаление данных
          Если вы желаете удалить предоставленную вами информацию при регистрации, пожалуйста, отправьте запрос на адрес
          help@autocanada.kg. Мы обработаем ваш запрос в течение 2-3 недель.

          Изменения в политике конфиденциальности
          Мы оставляем за собой право вносить изменения в нашу политику конфиденциальности в любое время. Если мы внесем
          изменения в данную политику, мы опубликуем уведомление на главной странице нашего веб-сайта. После публикации
          уведомления, ваше дальнейшее использование нашего веб-сайта будет считаться вашим согласием с обновленными
          условиями и положениями нашей политики конфиденциальности.

          Общее положение
          AutoCanada KG не несет ответственности за содержание или политику конфиденциальности веб-сайтов третьих сторон,
          на которые мы размещаем ссылки. Эти веб-сайты являются независимыми и имеют свои собственные политики и
          практики. Мы предоставляем ссылки на эти веб-сайты только в качестве удобства для пользователей. Пожалуйста,
          обратите внимание, что мы не несем ответственности за точность, достоверность или актуальность информации,
          предоставляемой на этих веб-сайтах, а также за содержимое файлов, которые пользователь может загрузить с этих
          сайтов. Рекомендуем вам ознакомиться с политиками конфиденциальности этих веб-сайтов, чтобы понять их подход к
          обработке и защите личной информации.
          Если у вас возникли вопросы относительно нашей политики конфиденциальности или ее реализации, пожалуйста,
          свяжитесь с нами по адресу help@autocanada.kg.


        </p>
      </div>
      <button type="button" class="custom-button blink-animate block" @click="close('policy', true)">
        Принять
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['close'],
};
</script>

<style scoped>
.my-login-modal-content {
  height: 800px;
  overflow: auto;
  padding: 40px 80px 120px;
}

.btn-modal-close {
  display: block!important;
  z-index: 9;
}

.my-background {
  background: #fff;
  position: relative;
}

.my-login-modal-content {
  background: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));
  mask-image: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
}

.my-login-modal-content::-webkit-scrollbar {
  width: 8px;
  background: #F5F4F6;
}

.my-login-modal-content::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
}

.my-login-modal-content::-webkit-scrollbar-thumb {
  background: #A02C12;
  border-radius: 10px;
}

.my-confirm-modal h2 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.my-confirm-modal p {
  font-size: 16px;
  line-height: 185%;
}

.my-confirm-modal p span {
  text-decoration: underline;
}

.my-background button {
  position: absolute;
  bottom: 30px;
  left: 80px;
  width: 80%;
}

@media screen and (max-width: 820px){
  .my-background {
    width: 80%;
  }

  .my-login-modal-content {
    width: 100%;
    height: 600px;
    margin-right: 0;
  }

  .my-confirm-modal h2 {
    font-size: 18px;
  }

  .my-confirm-modal .my-login-modal-content p {
    font-size: 12px;
    line-height: 16px;
  }

  .my-background button {
    bottom: 30px;
    left: 30px;
  }
}
</style>