<template>
  <section class="about">
    <div class="container">
      <div class="row">
        <div class="col-md about-text-wrap">
          <h1 class="title">О компании</h1>
          <p class="about-text"><b>Auto Canada KG</b> специализируется на предоставлении услуг по поиску, подбору и
            покупке подержанных автомобилей
            из Канады и США с последующей доставкой в Кыргызстан.</p>

          <p class="about-text">На нашем сайте вы можете найти весь автопарк автомобилей, выставленных на аукционы <b>Copart.com,
            Iaai.com,
            Copart.ca, Impactauto.ca</b>. Также у нас имеется прямой доступ к аукционам <b>Adesa</b> и <b>Manheim</b>.
            Кроме этого, мы
            закупаем автомобили у официальных дилеров и частных лиц по всей Канаде, чтобы предоставить вам еще больше
            вариантов выбора.</p>
          <router-link to="/about" class="about-button blink-animate">Узнать подробнее</router-link>
        </div>
        <div class="col-md d-flex justify-content-center">
          <img src="../assets/img/about-img.png" class="about-image">
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
</script>