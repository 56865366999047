<template>
    <section class="features">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-12 feature-text-wrap">
                    <h1 class="title light">Наши преимущества</h1>
                    <div class="feature red">
                        <span class="feature-span">Экономия 30 - 40 % на автомобиле</span>
                    </div>
                    <div class="feature">
                        <ul>
                          <li>
                            Вы покупаете автомобили с проверенной историей, с подтверж-
                            денным пробегом, которые проходили регулярные тех. осмотры.
                          </li>
                          <li>
                            Заводские и дополнительные гарантии от официальных дилеров
                            обеспечивают дополнительную защиту и уверенность Вам.
                          </li>
                          <li>
                            В отличие от американских автомобилей, канадские имеют
                            спидометры в километрах.
                          </li>
                          <li>
                            Если вы ищете пикап, то Канада славится своими мощными
                            пикапами, которые пользуются популярностью во всем мире.
                          </li>
                          <li>
                            Цены на канадские автомобили привлекательны, а благодаря
                            выгодному курсу обмена можно сэкономить дополнительно.
                          </li>
                          <li>
                            Мы единственная компания в Кыргызстане, которая покрывает
                            все налоги при покупке и экспорте автомобиля из Канады.
                          </li>
                          <li>
                            На канадских аукционах наши клиенты получают больше
                            возможностей благодаря низкой конкуренции.
                          </li>
                          <li>
                            И наконец, доставка автомобиля из Канады быстрее, чем из
                            США, что является еще одним важным преимуществом.
                          </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7 col-12 feature-image-wrap">
                    <div class="feature-map-makers">
                        <span>Привлекательные цены</span>
                        <span>Измерения в километрах и литрах</span>
                        <span>Меньшая конкуренция, больше возможностей</span>
                        <span>Курсовая разница</span>
                        <span>Мы покрываем 100% всех налогов</span>
                    </div>
                    <img src="../assets/img/feature-img.png" class="feature-image">
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
</script>