<template>
    <div>
      <a href="https://www.liveinternet.ru/click" target="_blank">
        <img
          id="licnt5AB1"
          width="88"
          height="31"
          style="border:0"
          title="LiveInternet: number of pageviews and visitors for 24 hours is shown"
          alt=""
        />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      const script = document.createElement("script");
      script.innerHTML = `
        (function(d,s){d.getElementById("licnt5AB1").src=
        "https://counter.yadro.ru/hit?t52.7;r"+escape(d.referrer)+
        ((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+
        (s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
        ";h"+escape(d.title.substring(0,150))+";"+Math.random()})
        (document,screen)
      `;
      document.head.appendChild(script);
    }
  }
  </script>
  