<template>
  <span class="mobile-filter-button" v-on:click="showFilter">Фильтр поиска</span>
  <div class="left-bar-wrap car-filter" :class="filterStatus && 'active'">
    <h1 class="left-bar-title">Параметры поиска авто</h1>
    <div class="form-group check-box py-4">
      <label class="buy-now-label" @click="filterByAuctionDate('today')">
        <span :class="today_auction ? 'check-box-span check' : 'check-box-span'"></span>
        <span>Сегодня</span>
      </label>
      <label class="buy-now-label" @click="filterByAuctionDate('tomorrow')">
        <span :class="tomorrow_auction ? 'check-box-span check' : 'check-box-span'"></span>
        <span>Завтра</span>
      </label>
      <label class="buy-now-label" @click="filterByAuctionDate('week')">
        <span :class="this_week_auction ? 'check-box-span check' : 'check-box-span'"></span>
        <span>На этой неделе</span>
      </label>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive0" @click="checkFilter">
        <span id="labelActive0">Аукцион</span>
        <span :class="labelActive0 ? 'label-active' : ''" id="labelActive0"></span>
      </label>
      <multiselect
          multiple
          :class="labelActive0 ? '' : 'search-select-hidden'"
          v-model="auction"
          :options="auctions"
          :custom-label="nameWithLang"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :searchable="false"
          select-label="Выбрать"
          selected-label="Выбран"
          deselect-label="Удалить"
          no-options="Список пуст"
          label="title"
          track-by="id"
      ></multiselect>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive1" @click="checkFilter">
        <span id="labelActive1">Марка</span>
        <span :class="labelActive1 ? 'label-active'  : ''" id="labelActive1"></span>
      </label>
      <select :class="`search-select${labelActive1 ? '' : ' search-select-hidden'}`" v-model="brand">
        <option value="">Все марки</option>
        <option v-for="brand in brands" :key="brand" :value="brand.title">{{ brand.title }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive2" @click="checkFilter">
        <span id="labelActive2">Модель</span>
        <span :class="labelActive2 ? 'label-active' : ''" id="labelActive2"></span>
      </label>
      <multiselect
          multiple
          :class="labelActive2 ? '' : 'search-select-hidden'"
          v-model="model"
          :options="models"
          :custom-label="nameWithLang"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Введите название модели"
          select-label="Выбрать"
          selected-label="Выбран"
          deselect-label="Удалить"
          no-options="Список пуст"
          label="title"
          track-by="id"
      ></multiselect>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive9" @click="checkFilter">
        <span id="labelActive9">Год от</span>
        <span :class="labelActive9 ? 'label-active' : ''" id="labelActive9"></span>
      </label>
      <select :class="`search-select${labelActive9 ? '' : ' search-select-hidden'}`" v-model="yearFrom">
        <option value="">---</option>
        <option v-for="i in 24" :key="i" :value="year(i)" :selected="(year(i) == yearFrom) && 'selected'">{{
            year(i)
          }}
        </option>
      </select>
    </div>
    <div class="form-group pb-2">
      <label class="search-label" id="labelActive10" @click="checkFilter">
        <span id="labelActive10">Год до</span>
        <span :class="labelActive10 ? 'label-active' : ''" id="labelActive10"></span>
      </label>
      <select :class="`search-select${labelActive10 ? '' : ' search-select-hidden'}`" v-model="yearTo">
        <option value="">---</option>
        <option v-for="i in 24" :key="i" :value="year(i)" :selected="(year(i) == yearTo) && 'selected'">{{
            year(i)
          }}
        </option>
      </select>
    </div>
    <div class="form-group check-box">
      <label class="buy-now-label" @click="checkTerms">
        <span :class="buy_now ? 'check-box-span check' : 'check-box-span'"></span>
        <span>Buy now (купить сейчас)</span>
      </label>
    </div>
    <div class="form-group pt-4">
      <label class="search-label" id="labelActive3" @click="checkFilter">
        <span id="labelActive3">Пробег</span>
        <span :class="labelActive3 ? 'label-active'  : ''" id="labelActive3"></span>
      </label>
      <multiselect
          multiple
          :class="labelActive3 ? '' : 'search-select-hidden'"
          v-model="mileage"
          :options="staticMileage"
          :custom-label="nameWithLang"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :searchable="false"
          placeholder="Выберите подходящие пробеги"
          select-label="Выбрать"
          selected-label="Выбран"
          deselect-label="Удалить"
          no-options="Список пуст"
          label="title"
          track-by="id"
      ></multiselect>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive4" @click="checkFilter">
        <span id="labelActive4">Тип ТС</span>
        <span :class="labelActive4 ? 'label-active'  : ''" id="labelActive4"></span>
      </label>
      <select :class="`search-select${labelActive4 ? '' : ' search-select-hidden'}`" v-model="vehicle">
        <option v-for="vehicle in vehicles" :key="vehicle" :value="vehicle.title_en">
          {{ vehicle.title }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive5" @click="checkFilter">
        <span id="labelActive5">Расположение</span>
        <span :class="labelActive5 ? 'label-active' : ''" id="labelActive5"></span>
      </label>
      <select :class="`search-select${labelActive5 ? '' : ' search-select-hidden'}`" v-model="location">
        <option v-if="!cityList" value="" disabled>Выберите аукцион</option>
        <option v-else value="">---</option>
        <option v-for="location in cityList" :key="location" :value="location.id">{{ location.location }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive6" @click="checkFilter">
        <span id="labelActive6">Топливо</span>
        <span :class="labelActive6 ? 'label-active' : ''" id="labelActive6"></span>
      </label>
      <select :class="`search-select${labelActive6 ? '' : ' search-select-hidden'}`" v-model="fuel_type">
        <option value="">---</option>
        <option value="Flexible Fuel">Бензин</option>
        <option value="Diesel">Дизель</option>
        <option value="Gasoline">Газ</option>
        <option value="Hybrid">Гибрид</option>
        <option value="Electric">Электрический</option>
        <option value="PlugInHybrid">Подключаемый гибрид</option>
        <option value="Other">Другое</option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive7" @click="checkFilter">
        <span id="labelActive7">трансмиссия</span>
        <span :class="labelActive7 ? 'label-active' : ''" id="labelActive7"></span>
      </label>
      <select :class="`search-select${labelActive7 ? '' : ' search-select-hidden'}`" v-model="transmission">
        <option value="">---</option>
        <option value="AUTOMATIC">Автомат</option>
        <option value="MANUAL">Механика</option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive8" @click="checkFilter">
        <span id="labelActive8">Цвет кузова</span>
        <span :class="labelActive8 ? 'label-active' : ''" id="labelActive8"></span>
      </label>
      <select :class="`search-select${labelActive8 ? '' : ' search-select-hidden'}`" v-model="color_f">
        <option value="">---</option>
        <option v-for="color in colorList" :key="color" :value="color.id">{{ color.title }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="search-label" id="labelActive11" @click="checkFilter">
        <span id="labelActive11">Привод</span>
        <span :class="labelActive11 ? 'label-active'  : ''" id="labelActive11"></span>
      </label>
      <select :class="`search-select${labelActive11 ? '' : ' search-select-hidden'}`" v-model="drive">
        <option value="">Все</option>
        <option value="Front Wheel Drive">Передний</option>
        <option value="Rear-wheel drive">Задний</option>
        <option value="All-wheel drive">Полный привод</option>
        <option value="4x4 w/Rear Wheel Drv">4x4 w/Rear Wheel</option>
      </select>
    </div>
    <div class="form-group border-bottom pb-4 mb-3">
      <label class="search-label" id="labelActive12" @click="checkFilter">
        <span id="labelActive12">С подушкой безопасности</span>
        <span :class="labelActive12 ? 'label-active'  : ''" id="labelActive12"></span>
      </label>
      <select :class="`search-select${labelActive12 ? '' : ' search-select-hidden'}`" v-model="airbag">
        <option value="">Все</option>
        <option value="DEPLOYED">Сработанный</option>
        <option value="Intact">Нетронутый</option>
        <option value="Missing">Отсутствует</option>
        <option value="Good">В хорошем состоянии</option>
      </select>
    </div>
    <div class="d-flex justify-content-center">
      <a class="custom-button" v-on:click="search">Поиск</a>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <a class="custom-button btn-reset" v-on:click="reset">Сброс</a>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

const STATIC_MILEAGE = [
  {id: '', title: 'Все'},
  {id: '0-25000', title: '0 - 25 000'},
  {id: '25000-50000', title: '25 000 - 50 000'},
  {id: '50000-75000', title: '50 000 - 75 000'},
  {id: '75000-100000', title: '75 000 - 100 000'},
  {id: '100000-150000', title: '100 000 - 150 000'},
  {id: '150000-200000', title: '150 000 - 200 000'},
  {id: '200000', title: '> 200 000'},
]

export default {
  components: {Multiselect},
  props: ['dataFilter'],
  created() {
    if (this.$store.getters.getAuctionList) {
      const auctions = this.$store.getters.getAuctionList
      const auctionStr = this.$route.query['auction']

      if (!auctions?.length || !auctionStr) {
        return this.$store.getters.getAuctionList
      }

      const newAuction = []
      auctionStr.split(',').forEach(str => {
        const filted = auctions.filter(auction => auction.id === parseInt(str))
        newAuction.push(filted[0])
      })

      this.auction = newAuction
    }

    const mileage_from = this.$route.query['mileage_from']
    const mileage_to = this.$route.query['mileage_to']

    const newArray = []
    STATIC_MILEAGE.map(item => {
      const array = item.id.split('-')

      if (array[0] === mileage_from) {
        newArray.push(item)
        return
      }
      if (array && array.length > 1 && array[1] === mileage_to) {
        newArray.push(item)
      }
    })

    this.mileage = newArray
  },
  data() {
    return {
      auction: '',
      brand: this.dataFilter.brand,
      model: '',
      yearMin: 1999,
      yearMax: new Date().getFullYear() + 2,
      yearFrom: this.dataFilter.yearFrom,
      yearTo: this.dataFilter.yearTo,
      priceFrom: '',
      priceTo: '',
      filterStatus: false,
      vehicle: this.$route.query['vehicle_type'] ? this.$route.query['vehicle_type'] : 'AUTOMOBILE',
      airbag: this.$route.query['airbags'] ? this.$route.query['airbags'] : '',
      drive: this.$route.query['drive_unit'] ? this.$route.query['drive_unit'] : '',
      location: '',
      fuel_type: '',
      transmission: '',
      color_f: '',
      buy_now: false,
      mileage: '',
      today_auction: this.$route.query['today_auction'] === 'true',
      tomorrow_auction: this.$route.query['tomorrow_auction'] === 'true',
      this_week_auction: this.$route.query['this_week_auction'] === 'true',
      labelActive0: false,
      labelActive1: false,
      labelActive2: false,
      labelActive3: false,
      labelActive4: false,
      labelActive5: false,
      labelActive6: false,
      labelActive7: false,
      labelActive8: false,
      labelActive9: false,
      labelActive10: false,
      labelActive11: false,
      labelActive12: false,
      staticMileage: STATIC_MILEAGE,
      cityList: ''
    }
  },
  computed: {
    auctions() {
      return this.$store.getters.getAuctionList
    },
    vehicles() {
      return [
        {
          "id": 1,
          "title": "Автомобили",
          title_en: 'AUTOMOBILE'
        },
        {
          "id": 35,
          "title": "Мотоциклы",
          title_en: 'MOTORCYCLE'
        },
        {
          "id": 36,
          "title": "Квадрациклы",
          title_en: 'ATV'
        },
        {
          "id": 37,
          "title": "Водные мотоциклы/катера",
          title_en: 'JET SKI'
        },
        {
          "id": 38,
          "title": "Трейлеры",
          title_en: 'TRAILER'
        },
        {
          "id": 42,
          "title": "Тяжелая спецтехника",
          title_en: 'HEAVY DUTY TRUCKS'
        },
        {
          "id": 45,
          "title": "Спец. машины",
          title_en: 'SPECIALIST'
        },
        {
          "id": 46,
          "title": "Краны",
          title_en: 'CRANE'
        },
        {
          "id": 39,
          "title": "Грузовики",
          title_en: 'TRUCK'
        },
        {
          "id": 49,
          "title": "Другие",
          title_en: 'OTHER'
        },
        {
          "id": 40,
          "title": "Микроавтобусы/Cargo",
          title_en: 'MINIBUSES/CARGO'
        },
        {
          "id": 41,
          "title": "Прицепы и п/прицепы",
          title_en: 'TRAILERS'
        },
        {
          "id": 43,
          "title": "Сельхоз. техника",
          title_en: 'AGRICULTURAL'
        },
        {
          "id": 44,
          "title": "Промышленная техника",
          title_en: 'INDUSTRIAL EQUIPMENT'
        }
      ]
    },
    brands() {
      return this.$store.getters.getBrandList
    },
    models() {
      return this.$store.getters.getModelList
    },
    bodyList() {
      return (this.$store.getters.getCalculatorBodyList)
          ? this.$store.getters.getCalculatorBodyList
          : this.$store.dispatch('getCalculatorBodyList')
    },
    colorList() {
      return (this.$store.getters.getColorList)
          ? this.$store.getters.getColorList
          : this.$store.dispatch('getColorList')
    }
  },
  watch: {
    async auction() {
      if (!this.auction || this.auction?.length === 0) {
        this.cityList = ''
        return
      }
      const newArray = []

      if (this.auction?.length && this.auction[0]?.id) {
        this.auction.map(item => {
          newArray.push(item.id)
        })
      }

      await this.$store.dispatch('getCityList', newArray.join(','))
      this.cityList = this.$store.getters.getCityList
    },
    auctions() {
      const auctions = this.$store.getters.getAuctionList
      const auctionStr = this.$route.query['auction']

      if (!auctions?.length || !auctionStr) {
        return this.$store.getters.getAuctionList
      }

      const newAuction = []
      auctionStr.split(',').forEach(str => {
        const filted = auctions.filter(auction => auction.id === parseInt(str))
        newAuction.push(filted[0])
      })

      this.auction = newAuction

      return this.$store.getters.getAuctionList
    },
    vehicle() {
      if (this.vehicle !== '') {
        this.brand = ''
        this.model = ''
        this.$store.dispatch('getBrandList', this.vehicle)
      }
    },
    models() {
      const models = this.$store.getters.getModelList
      const modelsStr = this.$route.query['model']

      if (!models?.length || !modelsStr) {
        return this.$store.getters.getModelList
      }

      let newModel = []
      modelsStr.split(',').forEach(str => {
        const filted = models.filter(model => model.title === str)

        newModel.push(filted[0])
      })

      this.model = newModel.filter(data => data !== undefined)

      return this.$store.getters.getModelList
    },
    $route() {
      if (this.$store.getters.getAuctionList) {
        const auctions = this.$store.getters.getAuctionList
        const auctionStr = this.$route.query['auction']

        if (!auctions?.length || !auctionStr) {
          return this.$store.getters.getAuctionList
        }

        const newAuction = []
        auctionStr.split(',').forEach(str => {
          const filted = auctions.filter(auction => auction.id === parseInt(str))
          newAuction.push(filted[0])
        })

        this.auction = newAuction
      }

      this.brand = this.$route.query['brand'] || ''
      this.yearFrom = this.$route.query['min_year']
      this.yearTo = this.$route.query['max_year']
      this.vehicle = this.$route.query['vehicle_type'] || 'AUTOMOBILE'
      this.airbag = this.$route.query['airbags'] ? this.$route.query['airbags'] : ''
      this.drive = this.$route.query['drive_unit'] ? this.$route.query['drive_unit'] : ''
      this.today_auction = this.$route.query['today_auction'] === 'true'
      this.tomorrow_auction = this.$route.query['tomorrow_auction'] === 'true'
      this.this_week_auction = this.$route.query['this_week_auction'] === 'true'
    },
    value() {
      this.priceFrom = this.value[0]
      this.priceTo = this.value[1]
    },
    brand() {
      if (this.brand !== "") {
        this.model = ''
        this.$store.dispatch('getModelList', this.brand)
      } else {
        this.model = ''
        this.$store.commit('setModelList', '')
      }
    },
  },
  methods: {
    year(count) {
      return this.yearMax - count
    },
    search() {
      const copyModels = this.model
      const copyMileage = this.mileage
      let newArray = []
      const newMileage = {
        mileage_from: 0,
        mileage_to: 1000000000,
      }

      if (copyModels) {
        copyModels.map(item => {
          newArray.push(item.title)
        })
      }
      if (Array.isArray(copyMileage) && copyMileage.length !== 0) {
        const newArrayMileage = copyMileage.map(item => {
          return item.id
        })
        const numbers = newArrayMileage.map(range => range.split('-').map(num => parseInt(num))).flat();
        const minNumber = Math.min(...numbers);
        const maxNumber = Math.max(...numbers);

        if (minNumber === maxNumber) {
          newMileage.mileage_from = minNumber
        } else {
          newMileage.mileage_from = minNumber
          newMileage.mileage_to = maxNumber
        }
      }

      const copyAuction = this.auction
      const newArrayAuction = []

      if (copyAuction) {
        copyAuction.map(item => {
          newArrayAuction.push(item.id)
        })
      }

      var query = {
        auction: this.auction ? newArrayAuction.join(',') : '',
        'vehicle_type': this.vehicle ? this.vehicle : 'AUTOMOBILE',
        brand: this.brand ? this.brand : '',
        model: newArray ? newArray?.join(',') : '',
        'min_year': this.yearFrom ? this.yearFrom : '',
        'max_year': this.yearTo ? this.yearTo : '',
        priceFrom: this.priceFrom ? this.priceFrom : '',
        priceTo: this.priceTo ? this.priceTo : '',
        mileage_from: newMileage.mileage_from || 0,
        mileage_to: newMileage.mileage_to || 1000000000,
        body: this.body || '',
        location: this.location || '',
        fuel_type: this.fuel_type || '',
        transmission: this.transmission || '',
        color_f: this.color_f || '',
        buy_now: this.buy_now,
        airbags: this.airbag,
        drive_unit: this.drive,
        today_auction: this.today_auction,
        tomorrow_auction: this.tomorrow_auction,
        this_week_auction: this.this_week_auction,
        sort_date: this.$route.query['sort_date'] || '',
        sort_year: this.$route.query['sort_year'] || '',
        brand_sort: this.$route.query['brand_sort'] || '',
        model_sort: this.$route.query['model_sort'] || '',
      }
      var search = new URLSearchParams(query)
      this.$router.push('/auction?' + search)
    },
    showFilter() {
      this.filterStatus = !this.filterStatus && true
    },
    checkTerms() {
      this.buy_now = !this.buy_now
    },
    reset() {
      this.auction = ''
      this.brand = ''
      this.model = ''
      this.yearMin = ''
      this.yearMax = '2023'
      this.yearFrom = ''
      this.yearTo = ''
      this.priceFrom = ''
      this.priceTo = ''
      this.filterStatus = false
      this.mileage_from = 0
      this.mileage_to = 1000000000
      this.vehicle = ''
      this.location = ''
      this.fuel_type = ''
      this.transmission = ''
      this.color_f = ''
      this.buy_now = false
      this.mileage = ''
      this.today_auction = false
      this.tomorrow_auction = false
      this.this_week_auction = false
      this.labelActive1 = false
      this.labelActive2 = false
      this.labelActive3 = false
      this.labelActive4 = false
      this.labelActive5 = false
      this.labelActive6 = false
      this.labelActive7 = false
      this.labelActive8 = false
      this.labelActive9 = false
      this.labelActive10 = false
      this.labelActive11 = false
      this.labelActive12 = false
      this.airbag = ''
      this.drive = ''

      this.$router.push('/auction')
    },
    checkFilter(e) {
      const name = e.target.id
      this[name] = !this[name]
    },
    nameWithLang({title}) {
      return title
    },
    filterByAuctionDate(name) {
      if (name === 'today') {
        this.today_auction = !this.today_auction
        this.tomorrow_auction = false
        this.this_week_auction = false
      } else if (name === 'tomorrow') {
        this.tomorrow_auction = !this.tomorrow_auction
        this.today_auction = false
        this.this_week_auction = false
      } else if (name === 'week') {
        this.this_week_auction = !this.this_week_auction
        this.tomorrow_auction = false
        this.today_auction = false
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect__tags {
  padding: 8px;
  background: rgba(30, 30, 30, 0.01);
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.multiselect__placeholder {
  margin: 0;
  font-size: 16px;
}

.multiselect__select::before {
  top: 50%;
}
</style>

<style scoped>
.left-bar-wrap .custom-select {
  padding: 0;
}

@media screen and (max-width: 720px) {
  .left-bar-wrap .multiselect {
    
  }
}
</style>