<template>
    <Header class="light" theme="light"></Header>
    <section class="cars" id="custom">
        <div class="container">
            <h1 class="title">Авто в наличии</h1>
            <div class="row cars-list mb-4">
                <div v-for="(car, index) in cars.results" :key="index" class="col-md-3 col-12">
                    <div class="car-card">
                        <div class="car-thumbnail">
                            <img :src="car.image" :alt="car.brand + ' ' + car.year" v-if="car.image">
                        </div>
                        <div class="car-body">
                            <h3 class="car-title">{{ car.title }}</h3>
                            <span class="car-price pb-3">Цена: <b>${{ car.price }}</b></span>
                            <div class="row car-info">
                                <div class="col-md-6 col-6">
                                    <i class="mileage"></i>
                                    <b>{{ car.mileage ? car.mileage : '0' }} км</b>
                                    <span>Пробег</span>
                                </div>
                                <div class="col-md-6 col-6">
                                    <i class="year"></i>
                                    <b>{{ car.year ? car.year : '-' }}</b>
                                    <span>Год</span>
                                </div>
                            </div>
                            <div class="row car-info pb-2">
                                <div class="col-md-6 col-6">
                                    <i class="transmission"></i>
                                    <b>{{ car.transmission ? car.transmission : '-' }}</b>
                                    <span>КПП</span>
                                </div>
                                <div class="col-md-6 col-6">
                                    <i class="fuel"></i>
                                    <b>{{ car.fuel ? car.fuel : '-' }}</b>
                                    <span>Двигатель</span>
                                </div>
                            </div>
                            <router-link :to="'/stock/detail/' + car.id" class="car-btn" target="_blank">Подробнее</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pag-list" v-if="cars.previous !== null || cars.next !== null">
                <a class="pag-link pag-arrow-left" :class="cars.previous === null && 'disabled'" v-on:click="pagination(cars.previous)">Предыдущая</a>
                <a class="pag-link pag-arrow-right" :class="cars.next === null && 'disabled'" v-on:click="pagination(cars.next)">Следующая</a>
            </div>
        </div>
    </section>
    <Contacts></Contacts>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Contacts,
        Footer
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        cars() {
            return (this.$store.getters.getStockCars)
            ? this.$store.getters.getStockCars
            : this.$store.dispatch('getStockCars')
        }
    },
    methods: {
        scrollToCars() {
            document.getElementById('cars').scrollIntoView({ behavior: 'smooth' })
        },
        pagination(item) {
            this.$router.push('/stock?' + item.split('?')[1])
        }
    }
}
</script>