<template>
    <div>
        <Header></Header>
        <Main></Main>
        <Search></Search>
        <SearchBrand></SearchBrand>
        <Cars></Cars>
        <About></About>
        <Features></Features>
        <Partners></Partners>
        <Reviews></Reviews>
        <Contacts></Contacts>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import Main from '../components/Main.vue'
import Search from '../components/Search.vue'
import SearchBrand from '../components/SearchBrand.vue'
import Cars from '../components/Cars.vue'
import About from '../components/About.vue'
import Features from '../components/Features.vue'
import Partners from '../components/Partners.vue'
import Reviews from '../components/Reviews.vue'
import Contacts from '../components/Contacts.vue'
import Footer from '../components/Footer.vue'

export default {
    data() {
        return {
            title: ''
        }
    },
    components: {
        Header,
        Main,
        Search,
        SearchBrand,
        Cars,
        About,
        Features,
        Partners,
        Reviews,
        Contacts,
        Footer
    }
}
</script>