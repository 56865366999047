<template>
  <Header></Header>
  <section class="page-header auction-bg">
    <div class="container">
    </div>
  </section>
  <Search
      section="auction"
      class="auction-wrap"
      :count="cars?.count || 0"
      :dataFilter="{brand: this.brand, yearFrom: this.yearFrom, yearTo: this.yearTo}"
  ></Search>
  <section class="cars" id="cars">
    <div class="container">
      <div class="row mb-4">
        <div class="sort-cars">
          <select class="search-select" v-model="sort" @change="sortCars">
            <option value="" disabled>Сортировать</option>
            <option value="new">По году (сначала новые)</option>
            <option value="old">По году (сначала старые)</option>
            <option value="near">Дата торгов (сначала ближайшие)</option>
            <option value="late">Дата торгов (сначала поздние)</option>
            <option v-if="!this.$route.query['brand']" value="a_z_">По марке (от А до Я)</option>
            <option v-if="!this.$route.query['brand']" value="z_a_">По марке (от Я до А)</option>
            <option v-if="!this.$route.query['brand']" value="a_z">По модели (от А до Я)</option>
            <option v-if="!this.$route.query['brand']" value="z_a">По модели (от Я до А)</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="left-bar">
            <LeftBarFilter
                :dataFilter="{brand: this.brand, yearFrom: this.yearFrom, yearTo: this.yearTo}"
            ></LeftBarFilter>
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="car-card car-card-v2 mb-4" v-for="car in cars.results" :key="car">
            <div class="row">
              <div class="col-md-5 p-0">
                <div class="car-thumbnail">
                  <img :src="car.image" :alt="car.brand" v-if="car.image">
                </div>
              </div>
              <div class="col-md-7">
                <div class="car-body">
                  <h3 class="car-title">{{ car.brand + ' ' + (car.model ? car.model : '') }}</h3>
                  <p class="car-price mb-3 d-flex align-items-center">
                    <span>Текущая ставка: <b>${{ car.price }}</b></span>
                    <span class="car-price-last-block">
                      <router-link v-if="car.buy_now_price"  :to="'/detail/' + car.id" class="car-price-last" target="_blank">Buy now</router-link>
                      <span><b>${{ car.buy_now_price }}</b></span>
                    </span>
                  </p>
                  <span class="car-auction-date">Дата аукциона: <b>{{
                      car.auction_date ?
                      String(car.auction_date).replace(' ', 'г.,') + 'ч.' : '---'
                    }}</b></span>
                  <div class="row car-info">
                    <div class="col-md-4 col-6 mb-3">
                      <i class="mileage"></i>
                      <b>{{ car.mileage ? car.mileage : '0' }} км</b>
                      <span>Пробег</span>
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                      <i class="year"></i>
                      <b>{{ car.year ? car.year : '-' }}</b>
                      <span>Год</span>
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                      <i class="transmission"></i>
                      <b>{{ car.transmission ? car.transmission : '-' }}</b>
                      <span>КПП</span>
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                      <i class="fuel"></i>
                      <b>{{ car.fuel ? car.fuel : '-' }}</b>
                      <span>Топливо</span>
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                      <i class="volume"></i>
                      <b>{{ car.volume ? car.volume : '-' }}</b>
                      <span>Двигатель</span>
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                      <i class="body"></i>
                      <b>{{ car.body ? car.body : '-' }}</b>
                      <span>Кузов</span>
                    </div>
                  </div>
                  <div class="car-footer">
                    <div class="car-footer-wrap">
                      <span class="car-vin">VIN: <b>{{ car.vin ? car.vin : '-' }}</b></span>
                      <div class="car-flag-wrap">
                        <img :src="car.flag" :alt="car.auction">
                        <b>{{ car.auction }}</b>
                      </div>
                    </div>
                    <router-link :to="'/detail/' + car.id" class="car-btn" target="_blank">Подробнее</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pag-list" v-if="cars.previous !== null || cars.next !== null">
            <a class="pag-link pag-arrow-left" :class="cars.previous === null && 'disabled'"
               v-on:click="pagination(cars.previous)">Предыдущая</a>
            <a class="pag-link pag-arrow-right" :class="cars.next === null && 'disabled'"
               v-on:click="pagination(cars.next)">Следующая</a>
          </div>
          <div class="box-wrap" v-if="!(cars?.results?.length !== 0)">
            <h3 class="wrap-title text-center">Ничего не найдено</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Contacts></Contacts>
  <Footer></Footer>
</template>

<script>
import {useHead} from '@vueuse/head'
import Header from '@/components/Header.vue'
import Search from '../components/Search.vue'
import LeftBarFilter from '@/components/LeftBarFilter.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
  setup() {
    useHead({
      title: 'Аукцион - AutoCanada',
    })
  },
  data() {
    return {
      sort: this.$route.query['sort_year'] || this.$route.query['sort_date'] || this.$route.query['brand_sort'] || this.$route.query['model_sort'] || '',
      brand: this.$route.query['brand'] || '',
      yearFrom: this.$route.query['min_year'] || '',
      yearTo: this.$route.query['max_year'] || '',
    }
  },
  components: {
    Header,
    Search,
    LeftBarFilter,
    Contacts,
    Footer
  },
  created() {
    if (this.$route.query['brand']) {
      this.$store.dispatch('getModelList', this.$route.query['brand'])
    }
    this.$store.dispatch('getCarList', this.$router.currentRoute.value.fullPath.split('?')[1] || 'vehicle_type=AUTOMOBILE')
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    auctions() {
      return (this.$store.getters.getAuctionList)
          ? this.$store.getters.getAuctionList
          : this.$store.dispatch('getAuctionList')
    },
    brands() {
      return this.$store.getters.getBrandList
    },
    models() {
      return this.$store.getters.getModelList
    },
    cars() {
      return this.$store.getters.getCarList
    }
  },
  watch: {
    $route() {
      this.$store.dispatch('getCarList', this.$router.currentRoute.value.fullPath.split('?')[1])
      this.scrollToCars()
      this.sort = this.$route.query['sort_year'] || this.$route.query['sort_date'] || this.$route.query['brand_sort'] || this.$route.query['model_sort'] || ''
    },
  },
  mounted: function () {
    if (Object.keys(this.$route.query).length !== 0) {
      document.getElementById('cars').scrollIntoView({behavior: 'smooth'})
    }
  },
  methods: {
    scrollToCars() {
      document.getElementById('cars').scrollIntoView({behavior: 'smooth'})
    },
    pagination(item) {
      this.$router.push('/auction?' + item.split('?')[1])
    },
    sortCars() {
      const query = {
        vehicle_type: this.$route.query['vehicle_type'] || 'AUTOMOBILE',
        brand: this.$route.query['brand'] || '',
        model: this.$route.query['model'] || '',
        min_year: this.$route.query['min_year'] || '',
        max_year: this.$route.query['max_year'] || '',
        auction: this.$route.query['auction'] || '',
        priceFrom: this.$route.query['priceFrom'] || '',
        priceTo: this.$route.query['priceTo'] || '',
        mileage_from: this.$route.query['mileage_from'] || 0,
        mileage_to: this.$route.query['mileage_to'] || 1000000000,
        body: this.$route.query['body'] || '',
        location: this.$route.query['location'] || '',
        fuel_type: this.$route.query['fuel_type'] || '',
        transmission: this.$route.query['transmission'] || '',
        color_f: this.$route.query['color_f'] || '',
        buy_now: this.$route.query['buy_now'] || '',
        airbags: this.$route.query['airbags'] || '',
        drive_unit: this.$route.query['drive_unit'] || '',
        today_auction: this.$route.query['today_auction'] === 'true',
        tomorrow_auction: this.$route.query['tomorrow_auction'] === 'true',
        this_week_auction: this.$route.query['this_week_auction'] === 'true',
      }

      const value = this.sort

      if (value === 'near' || value === 'late') {
        query.sort_date = value
        const search = new URLSearchParams(query)
        this.$router.push('/auction?' + search)
      } else if (value === 'new' || value === 'old') {
        query.sort_year = value
        const search = new URLSearchParams(query)
        this.$router.push('/auction?' + search)
      } else if (value === 'a_z_' || value === 'z_a_') {
        query.brand_sort = value
        const search = new URLSearchParams(query)
        this.$router.push('/auction?' + search)
      } else if (value === 'a_z' || value === 'z_a') {
        query.model_sort = value
        const search = new URLSearchParams(query)
        this.$router.push('/auction?' + search)
      }
    }
  },
}
</script>