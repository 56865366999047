<template>
  <div class="my-modal" @click="close('register')">
    <div class="my-modal-content" @click.stop>
      <div class="btn-modal-close" @click="close('register-min')"/>
      <h2>Регистрация</h2>

      <form @submit.prevent="register">
        <div class="my-modal-rows">
          <div class="my-modal-row">
            <input type="text" id="fullName" v-model="first_name" required placeholder="Имя"/>
          </div>

          <div class="my-modal-row">
            <input type="text" id="last_name" v-model="last_name" placeholder="Фамилия"/>
          </div>

          <div class="my-modal-row">
            <input type="tel" id="phoneNumber" v-model="phone" @input="handleInput" @keydown="preventPlusSign" :required="send_type === 1" placeholder="Номер телефона"/>
          </div>

          <div class="my-modal-row">
            <input type="email" id="email" v-model="email" :required="send_type === 2" placeholder="Почта"/>
          </div>

          <div class="my-modal-row">
            <input
                :type="[showPassword ? 'text' : 'password']"
                id="password"
                v-model="password"
                required
                placeholder="Пароль"
            />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility">

            </span>
          </div>

          <div class="my-modal-row">
            <input
                :type="[showPassword ? 'text' : 'password']"
                id="confirmPassword"
                v-model="confirmPassword"
                required
                placeholder="Повторите пароль"
            />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility">

            </span>
          </div>
        </div>

        <div class="check-box">
          <label @click="checkAuth">
            <span :class="[send_type === 2 ? 'check check-box-span' : 'check-box-span']"></span>
            <span class="check-box-text">Верификация по почте</span>
          </label>
          <label @click="checkAuth">
            <span :class="[send_type === 1 ? 'check check-box-span' : 'check-box-span']"></span>
            <span class="check-box-text">Верификация по телефону номера</span>
          </label>

          <label>
            <span :class="[acceptRefund ? 'check check-box-span' : 'check-box-span']" @click="acceptRefund = !acceptRefund"></span>
            <span class="check-box-text">Принимаю <span @click="checkRefund">условия  пользовательского соглашения</span></span>
          </label>

          <label>
            <span :class="[acceptTerms ? 'check check-box-span' : 'check-box-span']" @click="acceptTerms = !acceptTerms"></span>
            <span class="check-box-text">Принимаю <span @click="checkTerms">условия  политики возврата денежных средств</span></span>
          </label>
          <label >
            <span :class="[acceptPrivacy ? 'check check-box-span' : 'check-box-span']" @click="acceptPrivacy = !acceptPrivacy"></span>
            <span class="check-box-text">Принимаю <span @click="checkPrivacy">условия политики конфиденциальности</span></span>
          </label>
        </div>

        <div class="btn-block">
          <button class="custom-button" type="submit" :disabled="!(acceptTerms && acceptPrivacy && acceptRefund && password && (password === confirmPassword))">Регистрация</button>
        </div>
      </form>

      <div class="another_links">
        <p>У вас есть профиль? <span @click="[openLogin(), close('register-all')]">Войти</span></p>
        <p>Забыли пароль? <span @click="[openModalPasswordRecovery(), close('register-all')]">Восстановить</span></p>
      </div>
      <ModalPrivacyPolicy v-if="privacyPolicy" :close="openInModal" />
      <ModalTermsPolicy v-if="privacyTerms" :close="openInModal"/>
      <ModalRefundPolicy v-if="privacyRefund" :close="openInModal"/>
      <div id="recaptcha-container"></div>
      <ModalConfirmation
          v-if="confirmationResult"
          :moreCodeForPhone="moreCodeForPhone"
          :close="closeConfirmationModal"
          :signInWithVerificationCode="signInWithVerificationCode"
          confirmationCode="true"
      />
    </div>
  </div>
</template>

<script>
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import { auth } from '@/firebase';
import ModalPrivacyPolicy from "@/components/ModalPrivacyPolicy.vue";
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import ModalTermsPolicy from "@/components/ModalTermsPolicy.vue";
import ModalRefundPolicy from "@/components/ModalRefundPolicy.vue";

export default {
  components: {ModalRefundPolicy, ModalTermsPolicy, ModalConfirmation, ModalPrivacyPolicy},
  data() {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      acceptPrivacy: false,
      acceptRefund: false,
      showPassword: false,
      send_type: 2,
      privacyPolicy: false,
      privacyTerms: false,
      privacyRefund: false,
      confirmationResult: '',
      appVerifier: ''

    };
  },
  mounted() {
    try {
      this.appVerifier = new RecaptchaVerifier('recaptcha-container', {'size': 'invisible'}, auth);
    } catch (e) {
      console.log('captcha error', e, auth)
    }
  },
  props: ['close', 'openLogin', 'openModalPasswordRecovery'],
  methods: {
    async register() {
      const data = {
        first_name: this.first_name,
        email: this.email,
        phone: this.phone,
        last_name: this.last_name,
        send_type: this.send_type,
        password: this.password,
      }

      if (this.send_type === 2) {
        this.$store.dispatch('postUser', data)
      }

      if (this.send_type === 1) {
        const phoneNumber = this.phone;
        this.$store.commit('setLoading', true)

        await signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
            .then(confirmationResult => {
              // Код отправлен успешно
              this.confirmationResult = confirmationResult;
              this.$store.dispatch('postUser', data)
              this.$store.commit('setLoading', false)
            })
            .catch(error => {
              console.log(error)
              this.$store.commit('notification', {message: 'Авторизация по телефону номера временно не работает'})
              alert('Авторизация по телефону номера временно не работает')
              this.$store.commit('setLoading', false)
            });
      }
    },
    async signInWithVerificationCode(verificationCode) {
      if (this.confirmationResult) {
        try {
          this.$store.commit('setLoading', true)
          await this.confirmationResult.confirm(verificationCode)
              .then(async result => {
                if (result.error) {
                  this.$store.commit('notification', {message: 'Неверный код'})
                  return
                }
                const idToken = await result.user.getIdToken(true)

                this.$store.dispatch('phoneVerify', {phone: this.phone, token: idToken})

                this.confirmationResult = ''
                this.$store.commit('setLoading', false)
              })
        } catch (e) {
          this.$store.commit('notification', {message: 'Неверный код'})
          this.$store.commit('setLoading', false)
        }
      }
    },
    moreCodeForPhone() {
      const phoneNumber = this.phone;
      this.$store.commit('setLoading', true)

      signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
          .then(confirmationResult => {
            // Код отправлен успешно
            this.confirmationResult = confirmationResult;
            this.$store.commit('setLoading', false)
            this.$store.commit('notification', {message: 'Код отправлен на ваш номер телефона', type: 'success'})
          })
          .catch(error => {
            console.log(error)
            this.$store.commit('setLoading', false)
            this.$store.commit('notification', {message: 'Повторная отправка кода не сработала'})
          });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    checkTerms() {
      this.openInModal('terms')
    },
    checkPrivacy() {
      this.openInModal('policy')
    },
    checkRefund() {
      this.openInModal('refund')
    },
    checkAuth() {
      this.send_type = this.send_type === 1 ? 2 : 1
    },
    openInModal(modal, check) {
      if (modal === 'policy') {
        this.privacyPolicy = !this.privacyPolicy
        this.acceptPrivacy = check
      } else if (modal === 'terms') {
        this.privacyTerms = !this.privacyTerms
        this.acceptTerms = check
      } else if (modal === 'refund') {
        this.privacyRefund = !this.privacyRefund
        this.acceptRefund = check
      }
    },
    closeConfirmationModal() {
      this.confirmationResult = ''
    },
    preventPlusSign(event) {
      if (event.key === "+") {
        event.preventDefault();
      }
    },
    handleInput(event) {
      const inputValue = event.target.value;

      // Проверяем, есть ли уже символ "+", иначе добавляем его в начало значения
      if (!inputValue.includes('+')) {
        this.phone = '+' + inputValue;
      }
    },
  }
};
</script>

<style>
.my-modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-modal-content {
  position: relative;
  background-color: #fff;
  padding: 60px 45px;
  border-radius: 10px;
  max-width: 980px;
}

.btn-modal-close {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 16px;
  height: 16px;
  background: url("../assets/img/icons/btn-close.png") no-repeat 50% / cover;
}

.my-modal h2 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  color: #1E1E1E;
  text-align: center;
}

.my-modal-rows {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 30px;
}

.my-modal-row {
  width: 48%;
  position: relative;
}

.my-modal label {
  display: block;
  margin-top: 10px;
}

.my-modal input[type="text"],
.my-modal input[type="tel"],
.my-modal input[type="email"],
.my-modal input[type="password"] {
  width: 100%;
  padding: 21px 17px 18px;
  margin-top: 5px;
  background: rgba(30, 30, 30, 0.05);
  border-radius: 2px;
  border: none;
}

.my-modal input::placeholder {
  font-size: 16px;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  background: url("../assets/img/icons/hide-password.png");
}

.show-password {
  background-image: url("../assets/img/icons/password.png")!important;
}

.check-box {
  margin: 50px auto 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
}

.my-modal .check-box label {
  width: 48%;
  display: flex;
  align-items: center;
  gap: 13px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7B7979;
}

.my-modal .check-box label span:first-child {
  cursor: pointer;
}

.check-box label .check-box-span {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: rgba(30, 30, 30, 0.1);
  border-radius: 2px;
}

.check-box label .check-box-span::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transition: background 0.3s;
}

.check-box .check-box-text span {
  color: #A02C12;
}

.check-box .check::before {
  background: #A02C12 url("../assets/img/icons/check.svg") no-repeat 50%;
}

.my-modal .btn-block {
  width: max-content;
  margin: 0 auto;
}

.my-modal button {
  width: 430px;
  font-size: 18px;
  line-height: 22px;
}

.my-modal button:disabled {
  pointer-events: none;
  background: #a1a0a0;
}

.another_links {
  margin: 70px auto 0;
  display: flex;
  justify-content: space-around;
  gap: 50px;
  max-width: 550px;
}

.another_links p {
  margin: 0;
  white-space: nowrap;
  color: #7B7979;
}

.another_links span {
  cursor: pointer;
  color: #A02C12;
}

@media screen and (max-width: 1280px){
  .my-modal h2 {
    margin: 0 0 12px;
    font-size: 32px;
    line-height: 36px;
  }

  .my-modal-content {
    padding: 30px;
    width: 78%;
  }

  .my-modal input[type="text"],
  .my-modal input[type="tel"],
  .my-modal input[type="email"],
  .my-modal input[type="password"] {
    padding: 14px 12px 12px;
    margin-top: 3px;
  }

  .check-box label {
    font-size: 14px;
    line-height: 16px;
  }

  .my-modal button {
    width: 360px;
    font-size: 14px;
    line-height: 17px;
  }

  .another_links {
    margin: 50px auto 0;
    gap: 40px;
  }
}

@media screen and (max-width: 820px){
  .my-modal h2 {
    margin: 0 0 6px;
    font-size: 20px;
    line-height: 24px;
  }

  .my-modal-content {
    width: 74%;
  }

  .btn-modal-close {
    display: block;
  }

  .my-modal-rows {
    justify-content: center;
    row-gap: 4px;
    margin-bottom: 10px;
  }

  .my-modal-row {
    width: 100%;
  }

  .my-modal input[type="text"],
  .my-modal input[type="tel"],
  .my-modal input[type="email"],
  .my-modal input[type="password"] {
    font-size: 12px;
    width: 100%;
    padding: 8px 6px 6px;
  }

  .password-toggle {
    background-size: 12px!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important;
  }

  .my-modal input::placeholder {
    font-size: 12px;
  }

  .check-box {
    width: 230px;
    margin: 0 auto 10px;
    gap: 0;
  }

  .my-modal .check-box label {
    width: 100%;
    gap: 10px;
    font-size: 10px;
    line-height: 12px;
  }

  .check-box label .check-box-span {
    width: 14px;
    height: 14px;
    min-width: 14px;
  }

  .check-box label .check-box-span::before {
    content: "";
    width: 14px;
    height: 14px;
    min-width: 14px;
    border-radius: 2px;
  }

  .check-box .check::before {
    background-size: 8px;
  }

  .my-modal .btn-block {
    width: 80%;
  }

  .my-modal button {
    width: 100%;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
  }

  .another_links {
    flex-direction: column;
    margin-top: 14px;
    gap: 20px;
    font-size: 11px;
  }
}
</style>