export default {
  state: {
    baseUrl: 'https://api.autocanada.kg/'
  },
  mutations: {
    setRequestResponse(state, data) {
      state.requestData = data
    }
  },
  actions: {
    sendRequest({commit, state}, data) {
      return new Promise((resolve) => {
        commit('clearNotification')
        if (!data.loader && data.loader !== false) {
          commit('setLoading', true)
        }
        const finalUrl = (data.baseUrl) ? data.baseUrl : state.baseUrl + data.endpoint

        let formData
        if (data.data?.avatar) {
          formData = new FormData()
          formData.append('avatar', data.data.avatar)
        }
        fetch(finalUrl, {
          method: formData ? 'PATCH' : (data.method ? data.method : 'POST'),
          timeout: 8000,
          headers: !formData ? {
            'Content-Type': 'application/json',
            'Authorization': data?.tokens?.access ? ` JWT ${data.tokens.access}` : null
          } : {'Authorization': data?.tokens?.access ? ` JWT ${data.tokens.access}` : null},
          body: formData ? formData : JSON.stringify(data.data)
        })
          .then(async (response) => {
            if (response.status === 401) {
              const refresh = data?.tokens?.refresh
              await fetch(state.baseUrl + 'auth/token/refresh/', {
                method: 'POST',
                timeout: 8000,
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({refresh: refresh})
              })
                .then(inResponse => {
                  if (inResponse.status === 400) {
                    commit('setUser', '')
                    return
                  }
                  return inResponse.json()
                })
                .then(async tokens => {
                  if (tokens) {
                    const serializedState = JSON.stringify({refresh: tokens?.refresh, access: tokens?.access})
                    localStorage.setItem('tokens', serializedState)

                    location.reload()

                    if (data.endpoint === 'auth/carbid_create_or_update/' || data.endpoint === 'car/favorite/add_or_remove/') {
                      commit('notification', {message: 'Что то пошло не так! Попробуйте снова!'})
                    }
                  }
                })

            }
            if (!data.loader && data.loader !== false) {
              commit('setLoading', false)
            }
            resolve(response)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }
  },
  getters: {
    getBaseUrl(state) {
      return state.baseUrl
    },
    getRequestData(state) {
      return state.requestData
    }
  }
}