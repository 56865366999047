export default {
    state: {
        slider: '',
        auctions: '',
        brands: '',
        brandImage: '',
        models: '',
        body: '',
        bodyImage: '',
        vehicleList: '',
        mainCars: '',
        partners: '',
        reviews: ''
    },
    mutations: {
        setMainSlider(state, payload) {
            state.slider = payload
        },
        setAuctionList(state, payload) {
            state.auctions = payload
        },
        setBrandList(state, payload) {
            state.brands = payload
        },
        setBrandImage(state, payload) {
            state.brandImage = payload
        },
        setModelList(state, payload) {
            state.models = payload
        },
        setBodyList(state, payload) {
            state.body = payload
        },
        setBodyImage(state, payload) {
            state.bodyImage = payload
        },
        setVehicleList(state, payload) {
            state.vehicleList = payload
        },
        setMainCars(state, payload) {
            state.mainCars = payload
        },
        setPartners(state, payload) {
            state.partners = payload
        },
        setReviews(state, payload) {
            state.reviews = payload
        }
    },
    actions: {
        async getMainSlider({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car_main/slider_list/' })
                .then(response => response.json())
                .then(data => {
                    commit('setMainSlider', data)
                })
        },
        async getAuctionList({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/auction_list/' })
                .then(response => response.json())
                .then(data => {
                    commit('setAuctionList', data)
                })
        },
        async getAuctionSecondList({ dispatch, commit }) {
            const sort = (arr) => {
                return arr.sort((a, b) => a.country - b.country).reverse()
                
            }
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/main_auction_list' })
                .then(response => response.json())
                .then(data => {
                    const _data = sort(data)
                    commit('setAuctionList', _data)
                })
        },
        async getBrandList({ dispatch, commit }, vehicleId = '') {
            const vehicle = vehicleId ? '?vehicle_type=' + vehicleId : 'vehicle_type=AUTOMOBILE'
            const excludeMercedes = (arr) => {
                return arr.map(el => {
                    if(el.title.includes('MERC') && el.title !== 'MERCEDES-BENZ'){
                        return el = null;
                    }else{
                        return el
                    }
                }).filter(el => el !== null)
            }
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/brand_list/' + vehicle, loader: false })
                .then(response => response.json())
                .then(data => {
                    const _data = excludeMercedes(data)
                    commit('setBrandList', _data)
                })
        },
        async getBrandImage({ dispatch, commit }) {
            const serializedState = localStorage.getItem('brands')

            const fixMercedes = (arr) => {
                const mercedes = arr.map(el => {
                    if(el.title === 'MERCEDES'){
                       return {...el, title: 'MERCEDES-BENZ'}
                    }else{return el}
                })
                return mercedes
            }

            if (serializedState !== null) {
                const brands = JSON.parse(serializedState)
                const lastDate = new Date(brands.date)
                const currentDate = new Date().toISOString();

                const endDate = new Date(lastDate.getTime() + 12 * 60 * 60 * 1000).toISOString();

                if (currentDate > endDate) {
                    dispatch('sendRequest', { method: 'GET', endpoint: 'car/main_brand_list/' })
                        .then(response => response.json())
                        .then(data => {
                            const _data = fixMercedes(data)
                            commit('setBrandImage', _data)
                            const stringifyData = JSON.stringify({ date: new Date().toISOString(), array: _data })
                            localStorage.setItem('brands', stringifyData)
                        })
                    return
                }

                if (brands && brands.length !== 0) {
                    commit('setBrandImage', brands.array)
                    return
                }
            }


            dispatch('sendRequest', { method: 'GET', endpoint: 'car/main_brand_list/' })
                .then(response => response.json())
                .then(data => {
                    const _data = fixMercedes(data)
                    commit('setBrandImage', _data)
                    const stringifyData = JSON.stringify({ date: new Date().toISOString(), array: _data })
                    localStorage.setItem('brands', stringifyData)
                })
        },
        async getModelList({ dispatch, commit }, brand) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/model_list/?brand=' + brand, loader: false })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json()
                    }
                })
                .then(data => {
                    if (data) {
                        commit('setModelList', data)
                    }
                })
        },
        async getBodyList({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/body_list/' })
                .then(response => response.json())
                .then(data => {
                    commit('setBodyList', data)
                })
        },
        async getBodyImage({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/body_for_calculator/' })
                .then(response => response.json())
                .then(data => {
                    commit('setBodyImage', data)
                })
        },
        async getVehicleList({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/vehicle_type_list/' })
                .then(response => response.json())
                .then(data => {
                    commit('setVehicleList', data)
                })
        },
        async getMainCars({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car_main/' })
                .then(response => response.json())
                .then(data => {
                    commit('setMainCars', data)
                })
        },
        async getPartners({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car_main/partner_list/' })
                .then(response => response.json())
                .then(data => {
                    commit('setPartners', data)
                })
        },
        async getReviews({ dispatch, commit }) {
            dispatch('sendRequest', { method: 'GET', endpoint: 'car/review/' })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    commit('setReviews', data)
                })
        },
        async feedBackForm({ dispatch, commit }, data) {
            dispatch('sendRequest', { endpoint: 'car_main/feedback/', data })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        commit('notification', { message: 'Успешно отправлено', type: 'success' })
                    } else {
                        commit('notification', { message: 'Произошла неизвестная ошибка, попробуйте позже' })
                    }
                })
        }
    },
    getters: {
        getMainSlider(state) {
            return state.slider
        },
        getAuctionList(state) {
            return state.auctions
        },
        getBrandList(state) {
            return state.brands
        },
        getBrandImage(state) {
            return state.brandImage
        },
        getModelList(state) {
            return state.models
        },
        getBodyList(state) {
            return state.body
        },
        getBodyImage(state) {
            return state.bodyImage
        },
        getVehicleList(state) {
            return state.vehicleList
        },
        getMainCars(state) {
            return state.mainCars
        },
        getPartners(state) {
            return state.partners
        },
        getReviews(state) {
            return state.reviews
        }
    }
}