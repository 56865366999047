import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueMaskDirective } from 'v-mask'
import { createHead } from '@vueuse/head'

const head = createHead()
const vMaskV2 = VueMaskDirective
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
}
// importing the global css file
import './assets/css/main.css'
createApp(App)
.use(router)
.use(store)
.use(head)
.directive('mask', vMaskV3)
.mount('#app')