<template>
  <div class="container deposit-rows">
<!--    <div class="deposit-row">-->
<!--      <div class="deposit-images">-->
<!--        <div>-->
<!--          <img src="../assets/img/icons/deposit.png" alt="deposit">-->
<!--        </div>-->
<!--        <p>Deposit</p>-->
<!--      </div>-->
<!--      <div class="deposit-second">-->
<!--        <input type="text" placeholder="Введите сумму депозита">-->
<!--      </div>-->
<!--      <div class="deposit-btn">-->
<!--        <button class="personal-btn">Заказать инвойс</button>-->
<!--      </div>-->
<!--    </div>-->

    <div class="deposit-row">
      <div class="deposit-images">
        <div>
          <img src="../assets/img/icons/visa.png" alt="visa">
        </div>
        <div>
          <img src="../assets/img/icons/mastercard.png" alt="visa">
        </div>
      </div>
      <div class="deposit-second">
        <p>Пополнение через Visa, MasterCаrd или электронные кошельки.</p>
      </div>
      <div class="deposit-btn">
        <button class="personal-btn" @click="click(6)">Посмотреть</button>
      </div>
    </div>

    <div class="deposit-row">
      <div class="deposit-images">
        <div>
          <img src="../assets/img/icons/bank.png" alt="bank">
        </div>
        <p>Bank</p>
      </div>
      <div class="deposit-second">
        <p>Выбрав эту опцию, вы будете переадресованы на страницу с нашей банковской информацией.</p>
      </div>
      <div class="deposit-btn">
        <button class="personal-btn" @click="click(7)">Посмотреть</button>
      </div>
    </div>

    <div class="deposit-row">
      <div class="deposit-images">
        <div>
          <img src="../assets/img/icons/deposit.png" alt="deposit">
        </div>
        <p>Возврат депозита</p>
      </div>
      <div class="deposit-second d-flex gap-3 px-3">
        <textarea name="message" :class="comment ? 'active' : 'stock'" v-model="comment" required placeholder="Напиши комментарий"></textarea>
        <input type="number" v-model="bid" required placeholder="Сумма" >
      </div>
      <div class="deposit-btn">
        <button class="personal-btn" @click="backBid">Запросить</button>
      </div>
    </div>

    <div class="deposit-row">
      <div class="deposit-images">
        <div>
          <img src="../assets/img/icons/receipt-item.png" alt="receipt">
        </div>
        <p>История операции</p>
      </div>
      <div class="deposit-second">

      </div>
      <div class="deposit-btn">
        <button class="personal-btn" @click="click(9)">Посмотреть</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      bid: '',
      comment: '',
    }
  },
  props: ['click'],
  methods: {
    backBid() {
      if (!this.comment) {
        this.$store.commit('notification', {message: 'Напишите комментарий!'})
        return
      }

      if (!this.bid) {
        this.$store.commit('notification', {message: 'Введите сумму!'})
        return
      }

      this.$store.dispatch('backBid', {balance: this.bid, description: this.comment})
      this.bid = ''
      this.comment = ''
    }
  }
}
</script>

<style scoped>
  .deposit-rows {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 190px;
  }

  .deposit-row {
    background: #FFFFFF;
    box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 6%;
  }

  .deposit-images p {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }

  .deposit-images {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .deposit-second {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .deposit-second p {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .deposit-second textarea, .deposit-second input {
    padding: 20px;
    width: 70%;
    background: rgba(30, 30, 30, 0.05);
    border-radius: 2px;
    border: none;
    resize: none;
  }

  .deposit-second .stock {
    height: 60px;
  }

  .deposit-second .active {
    height: 120px;
  }

  .deposit-btn {
    width: 20%;
    display: flex;
    align-items: center;
  }

  .deposit-btn button {
    height: 60px;
    width: 250px;
  }

  @media screen and (max-width: 1280px){
    .deposit-images p {
      font-size: 22px;
      text-align: left;
    }

    .deposit-second p {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 1280px){
    .deposit-images p {
      font-size: 22px;
      text-align: left;
    }

    .deposit-second p {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 960px){
    .deposit-row {
      flex-wrap: wrap;
      gap: 30px;
    }

    .deposit-images {
      width: 100%;
    }

    .deposit-second {
      width: 60%;
    }

    .deposit-second textarea, .deposit-second input {
      width: 90%;
    }

    .deposit-btn {
      width: 28%;
    }
  }

  @media screen and (max-width: 960px){
    .deposit-row {
      flex-wrap: wrap;
      gap: 30px;
    }

    .deposit-images {
      width: 100%;
    }

    .deposit-second {
      width: 100%;
    }

    .deposit-second textarea, .deposit-second input {
      width: 90%;
    }

    .deposit-btn {
      width: 100%;
    }
  }
</style>