<template>
    <section class="cars" id="custom">
        <div class="container">
            <h1 class="title">Горячие Лоты</h1>
            <div class="cars-list">
                <Splide :options="mainOptions">
                    <SplideSlide v-for="(car, index) in cars" :key="index" class="p-2">
                        <div class="car-card">
                            <div class="car-thumbnail">
                                <img :src="car.image" :alt="car.brand + ' ' + car.year" v-if="car.image">
                            </div>
                            <div class="car-body">
                                <h3 class="car-title">{{ car.brand }}</h3>
                                <span class="car-price pb-3">Текущая ставка: <b>${{ car.price }}</b></span>
                                <div class="row car-info">
                                    <div class="col-md-6 col-6">
                                        <i class="mileage"></i>
                                        <b>{{ car.mileage ? car.mileage : '0' }} км</b>
                                        <span>Пробег</span>
                                    </div>
                                    <div class="col-md-6 col-6">
                                        <i class="year"></i>
                                        <b>{{ car.year ? car.year : '-' }}</b>
                                        <span>Год</span>
                                    </div>
                                </div>
                                <div class="row car-info pb-2">
                                    <div class="col-md-6 col-6">
                                        <i class="transmission"></i>
                                        <b>{{ car.transmission ? car.transmission : '-' }}</b>
                                        <span>КПП</span>
                                    </div>
                                    <div class="col-md-6 col-6">
                                        <i class="fuel"></i>
                                        <b>{{ car.fuel ? car.fuel : '-' }}</b>
                                        <span>Двигатель</span>
                                    </div>
                                </div>
                                <router-link :to="'/detail/' + car.id" class="car-btn" target="_blank">Подробнее</router-link>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    </section>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

export default {
    data() {
        return {
            mainOptions: {
                perPage: 4,
                perMove: 1,
                classes: {
                    prev: 'custom-class-prev',
                    next: 'custom-class-next',
                },
                breakpoints: {
                    1140: {
                        perPage: 3,
                        // gap: '.7rem',
                    },
                    960: {
                        perPage: 2,
                        // gap: '.7rem',
                    },
                    720: {
                        perPage: 2,
                        // gap: '.7rem',
                    },
                    540: {
                        perPage: 1,
                        // gap: '.7rem',
                    },
                },
                pagination: false
            }
        }
    },
    components: {
        Splide,
        SplideSlide
    },
    computed: {
        cars() {
            return (this.$store.getters.getMainCars)
            ? this.$store.getters.getMainCars
            : this.$store.dispatch('getMainCars')
        },
    }
}
</script>