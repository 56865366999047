<template>
  <div class="my-confirm-modal">
    <div class="my-login-modal-content" @click.stop>
      <div class="btn-modal-close btn-modal-close-confirm" @click="close('confirm')"/>
      <h2>Подтверждение</h2>
      <p v-if="active !== 'hidden'">Не пришло сообщение? Можно <span :class="this.active" @click="moreCode">прислать ещё</span> <span v-if="timer">через {{ timer }} секунд</span></p>
      <form @submit.prevent="confirm">
        <div class="my-login-modal-rows">
          <div class="my-login-modal-row">
            <input type="text" id="login" v-model="code" required placeholder="Код"/>
          </div>
        </div>

        <div class="btn-block">
          <button class="custom-button" type="submit">Завершить регистрацию</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: '',
      active: '',
      timer: 90,
    };
  },
  created() {
    const timer = setInterval(() => {
      this.timer = this.timer - 1
    }, 1000)

    setTimeout(() => {
      this.active = 'active-code'
      clearInterval(timer)
    }, 90000)
  },
  props: ['close', 'signInWithVerificationCode', 'confirmationCode', 'moreCodeForPhone'],
  methods: {
    confirm() {
      if (this.confirmationCode) {
        this.signInWithVerificationCode(this.code)
        return
      }

      const data = {
        email: this.$store.getters.getEmail,
        code: this.code,
      }

      this.$store.dispatch('emailVerify', data)
    },
    moreCode() {
      this.active = 'hidden'
      if (this.confirmationCode) {

        this.moreCodeForPhone()
        return
      }

      const data = {
        email: this.$store.getters.getEmail,
      }

      this.$store.dispatch('sendCode', data)
    }
  }
};
</script>

<style>
.my-confirm-modal {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-login-modal-content {
  position: relative;
  background-color: #fff;
  padding: 60px 150px 150px;
  border-radius: 10px;
  max-width: 980px;
  color: #1E1E1E;
}

.my-login-modal-content .btn-modal-close-confirm {
  display: block;
}

.my-confirm-modal h2 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  text-align: center;
}

.my-confirm-modal .my-login-modal-content span {
  pointer-events: none;
}

.my-confirm-modal .my-login-modal-content .active-code {
  pointer-events: auto!important;
  cursor: pointer;
  color: #A02C12;
}

.my-login-modal-rows {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
}

.my-login-modal-row {
  width: 430px;
  margin: 0 auto;
  position: relative;
}

.my-confirm-modal input {
  width: 100%;
  padding: 21px 17px 18px;
  margin-top: 5px;
  background: rgba(30, 30, 30, 0.05);
  border-radius: 2px;
  border: none;
}

.my-confirm-modal input::placeholder {
  font-size: 16px;
}

.my-confirm-modal .btn-block {
  width: max-content;
  margin: 40px auto 0!important;
}

.my-confirm-modal button {
  width: 430px;
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 820px){
  .my-confirm-modal h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .my-confirm-modal .my-login-modal-content p {
    font-size: 14px;
  }

  .my-confirm-modal button {
    width: 230px;
    font-size: 14px;
    line-height: 16px;
  }

  .my-confirm-modal .btn-block {
    margin: 20px auto 0!important;
  }
}
</style>