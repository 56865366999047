<template>
  <div class="footer">
    <ModalPrivacyPolicy v-if="privacyPolicy" :close="openInModal" />
    <ModalTermsPolicy v-if="privacyTerms" :close="openInModal"/>
    <ModalRefundPolicy v-if="privacyRefund" :close="openInModal"/>
    <div class="container d-flex align-items-around justify-content-around flex-wrap">
      <div class="footer-text">
        <p class="d-flex footer-text align-items-center flex-wrap m-0">
          <span class="" @click="openInModal('policy')">Политики конфиденциальности</span>
          <span class="" @click="openInModal('refund')">Пользовательское соглашение</span>
          <span @click="openInModal('terms')">Политика возврата денежных средств</span>
        </p>
      </div>
      <div class="d-flex flex-wrap footer-text align-items-center justify-content-around mt-3">
        <a href="#" class="footer-logo mb-3 mb-xxl-0">All Rights Recerved 2023</a>
        <span>Разработано компанией <a href="https://www.odigital.app" class="fw-bold text-decoration-none text-warning-emphasis">Oracle Digital</a></span>
        <VisitCounter/>
      </div>
    </div>
  </div>
</template>
<script>
  import ModalPrivacyPolicy from "@/components/ModalPrivacyPolicy.vue";
  import ModalRefundPolicy from "@/components/ModalRefundPolicy.vue";
  import ModalTermsPolicy from "@/components/ModalTermsPolicy.vue";
  import VisitCounter from "./ VisitCounter.vue";

  export default {
    components: {ModalTermsPolicy, ModalRefundPolicy, ModalPrivacyPolicy, VisitCounter},
    data() {
      return {
        privacyPolicy: false,
        privacyTerms: false,
        privacyRefund: false,
      }
    },
    methods: {
      openInModal(modal) {
        if (modal === 'policy') {
          this.privacyPolicy = !this.privacyPolicy
        } else if (modal === 'terms') {
          this.privacyTerms = !this.privacyTerms
        } else if (modal === 'refund') {
          this.privacyRefund = !this.privacyRefund
        }
      },
    }
  }
</script>

<style scoped>
  .footer-text {
    gap: 3rem;
    margin-left: 6%;
  }

  .footer-text span {
    cursor: pointer;
  }

  @media screen and (max-width: 1400px){
    .footer-text {
      margin: 0;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 720px){
    .footer-text p {
      flex-direction: column-reverse;
      align-items: start !important;
    }
    .footer-text{
      gap: 1rem;
    }
  }
</style>